import { memo } from 'react';
import styled from 'styled-components';

export const Container = memo(styled.div`
    max-width: 1440px;
    padding: 0 165px;
    margin: 0 auto;
    @media (max-width: 1280px) {
        padding: 0 65px;
    }
    @media (max-width: 992px) {
        padding: 0 32px;
    }
    @media (max-width: 768px) {
        padding: 0 15px;
    }
`);
