import { FC } from 'react';
import styled from 'styled-components';

interface PageTitleProps {
    title: string;
    subTitle?: string;
}

const PageTitle: FC<PageTitleProps> = ({ title, subTitle }) => {
    return (
        <StyledPageTitle>
            <h1>{title}</h1>
            <p>{subTitle}</p>
        </StyledPageTitle>
    );
};

const StyledPageTitle = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    width: 100%;
    margin-bottom: 64px;
    text-align: center;
    @media (max-width: 992px) {
        margin-bottom: 32px;
    }

    h1 {
        font-size: 48px;
        font-weight: 700;
        line-height: 120%;
        @media (max-width: 992px) {
            font-size: 26px;
        }
    }
    p {
        font-size: 22px;
        font-weight: 500;
        line-height: 130%;
        max-width: 720px;
        text-align: center;
        @media (max-width: 992px) {
            font-size: 16px;
        }
    }
`;

export default PageTitle;
