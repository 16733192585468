/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, memo } from 'react';
import { RegisterOptions } from 'react-hook-form';
import styled from 'styled-components';

type CustomInputProps = RegisterOptions & {
    register: any;
    placeholder?: string;
    type?: string;
    isError?: boolean;
};

const CustomInput: FC<CustomInputProps> = ({
    register,
    type,
    placeholder,
    isError,
}) => {
    return (
        <StyledCustomInput
            $isError={isError}
            {...register}
            type={type}
            placeholder={placeholder}
        />
    );
};

export const StyledCustomInput = memo(styled.input<{ $isError: boolean }>`
    padding: 13px 16px;
    border-radius: 4px;
    border: 1px solid
        ${({ $isError }) =>
            $isError ? 'var(--color-error)' : 'var(--color-primary-green)'};
    width: 100%;
    transition: var(--transition);
    font-size: 16px;
    font-weight: 400;
    line-height: 130%;
    color: ${({ $isError }) =>
        $isError ? 'var(--color-error)' : 'var(--color-text-main)'};
    &::placeholder {
        color: ${({ $isError }) =>
            $isError ? 'var(--color-error)' : 'var(--color-primary-gray)'};
        font-size: 16px;
        font-weight: 400;
        line-height: 130%;
    }
    &:hover {
        border-color: ${({ $isError }) =>
            $isError
                ? 'var(--color-error)'
                : 'var(--color-primary-green-hover)'};
    }
`);

export default CustomInput;
