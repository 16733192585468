import { FC } from 'react';
import styled from 'styled-components';
import { StyledSection } from '../../styles/section.styled';
import { Container } from '../../styles/container.styled';
import { StyledTitle } from '../../styles/title.styled';
import GratitudeSlider from './GratitudeSlider/GratitudeSlider';

const Gratitude: FC = () => {
    return (
        <StyledGratitude id="gratitude">
            <StyledSection>
                <Container>
                    <StyledTitle>Благодарности</StyledTitle>
                    <GratitudeSlider />
                </Container>
            </StyledSection>
        </StyledGratitude>
    );
};

const StyledGratitude = styled.div`
    padding-top: 80px;
    @media (max-width: 768px) {
        padding-top: 32px;
    }
`;

export default Gratitude;
