import { FC } from 'react';
import Select, {
    components,
    DropdownIndicatorProps,
    GroupBase,
} from 'react-select';
import styled from 'styled-components';
import { ReactComponent as ArrowIcon } from '../../../assets/icons/select.svg';
import { useAppDispatch } from '../../../store/hooks';
import { setInputsData } from '../../../store/slices/mainSlice';
import { ICustomSelectProps, Option } from './CustomSelect.types';

const CustomSelect: FC<ICustomSelectProps> = ({
    options,
    defaultOption,
    field,
}) => {
    const dispatch = useAppDispatch();
    // Определите тип параметра "event" в функции "onChange"

    const DropdownIndicator: FC<
        DropdownIndicatorProps<Option, boolean, GroupBase<Option>>
    > = (props) => {
        return (
            <components.DropdownIndicator {...props}>
                <ArrowIcon />
            </components.DropdownIndicator>
        );
    };

    const handleSelectChange = (value: Option) => {
        dispatch(
            setInputsData({
                tarif: value,
            }),
        );
        field.onChange(value);
    };
    return (
        <StyledCustomSelect>
            <Select
                {...field}
                onChange={handleSelectChange}
                options={options}
                defaultValue={defaultOption}
                components={{ DropdownIndicator }}
                readOnly
                isSearchable={false}
            />
        </StyledCustomSelect>
    );
};

const StyledCustomSelect = styled.div`
    && .css-1u9des2-indicatorSeparator {
        display: none;
    }
    && .css-1dimb5e-singleValue,
    && .css-qbdosj-Input {
        padding: 0;
        margin: 0;
    }
    && .css-1fdsijx-ValueContainer {
        padding: 13px 16px;
    }
    && .css-t3ipsp-control {
        border-color: var(--color-primary-green-hover);
        box-shadow: none;
        min-height: 100%;
        min-height: 47px !important;
        font-size: 16px;
    }
    && .css-13cymwt-control {
        border-radius: 4px;
        border: 1px solid var(--color-primary-green);
        width: 100%;
        transition: var(--transition);
        font-size: 16px;
        font-weight: 400;
        min-height: 47px !important;
        &::placeholder {
            color: var(--color-primary-gray);
            font-size: 16px;
            font-weight: 400;
        }
    }
    && .css-tr4s17-option {
        background: var(--color-primary-green);
    }
    && .css-d7l1ni-option {
        background: var(--color-primary-green-rgba);
    }
`;

export default CustomSelect;
