import imageSource1 from '../../../assets/images/sercificates/ИКП-min.jpg';
import imageSource2 from '../../../assets/images/sercificates/КВН-min.jpg';
import imageSource3 from '../../../assets/images/sercificates/НКББ-min.jpg';
import imageSource4 from '../../../assets/images/sercificates/Невская краса-min.jpeg';
import imageSource5 from '../../../assets/images/sercificates/Перспективы-min.jpg';
import imageSource6 from '../../../assets/images/sercificates/Ритм без границ-min.jpg';
import imageSource7 from '../../../assets/images/sercificates/Точка отсчета конкурс-min.jpg';
import imageSource8 from '../../../assets/images/sercificates/Центр соц.реабилитации-min.jpg';

export const grats = [
    imageSource1,
    imageSource6,
    imageSource2,
    imageSource8,
    imageSource3,
    imageSource4,
    imageSource5,
    imageSource7,
];
