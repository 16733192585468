import { FC } from 'react';
import styled from 'styled-components';
import { StyledSection } from '../../styles/section.styled';
import { Container } from '../../styles/container.styled';
import { StyledTitle } from '../../styles/title.styled';
import AboutSlider from './AboutSlider/AboutSlider';
import React from 'react';

const About: FC = () => {
    return (
        <StyledAbout id="about">
            <StyledSection>
                <Container>
                    <StyledTitle>О нас</StyledTitle>
                    <StyledAboutWrapper>
                        <StyledAboutInfo>
                            <p>
                                Наша миссия — сделать перемещение пассажиров с
                                ограниченными возможностями к передвижению{' '}
                                <b>доступным, безопасным и комфортным</b>. Мы
                                хотим помочь людям, которые нуждаются в особом
                                внимании, жить более полноценной жизнью, а их
                                близким избежать лишнего волнения за своих
                                родных.
                            </p>
                            <p>
                                Мы хотим, чтобы пассажирские перевозки этой
                                категории граждан стали{' '}
                                <b>
                                    {' '}
                                    общепринятой и общедоступной нормой нашей
                                    жизни
                                </b>
                                .
                            </p>
                        </StyledAboutInfo>
                        <AboutSlider />
                    </StyledAboutWrapper>
                </Container>
            </StyledSection>
        </StyledAbout>
    );
};

const StyledAbout = styled.div`
    padding-top: 80px;
    @media (max-width: 768px) {
        padding-top: 32px;
    }
    background: var(--color-bg-second);
    box-shadow: var(--shadow-main);
`;
const StyledAboutWrapper = styled.div`
    margin-top: 56px;
    display: flex;
    justify-content: space-between;
    gap: 32px;
    align-items: flex-start;
    @media (max-width: 992px) {
        flex-direction: column;
        align-items: center;
    }
    @media (max-width: 768px) {
        margin-top: 24px;
        margin-right: -16px;
        margin-left: -16px;
    }
`;
const StyledAboutInfo = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-width: 540px;
    @media (max-width: 992px) {
        max-width: 100%;
    }
    @media (max-width: 768px) {
        padding-left: 15px;
        padding-right: 15px;
    }
    p {
        font-size: 18px;
        font-weight: 400;
        line-height: 150%;
        @media (max-width: 768px) {
            font-size: 15px;
        }
    }
`;

export default About;
