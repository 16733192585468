/* eslint-disable @typescript-eslint/no-unused-vars */
import { format, utcToZonedTime } from 'date-fns-tz';
import ru from 'date-fns/locale/ru';
import { FC, useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import CustomDateTimePikerTimeinputs from './CustomDateTimePikerTimeinputs';
import styled from 'styled-components';
import { ReactComponent as CalendraIcon } from '../../../assets/icons/calendar.svg';

const moscowTimezone = 'Europe/Moscow';
const currentDateTime = new Date().setDate(new Date().getDate() + 1);
const moscowDateTime = utcToZonedTime(currentDateTime, moscowTimezone);

interface ICustomDateTimePickerProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    field: any;
}

const CustomDateTimePicker: FC<ICustomDateTimePickerProps> = ({ field }) => {
    const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < 992);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [hours, setHours] = useState<string>(
        moscowDateTime.getHours().toString(),
    );
    const [minutes, setMinutes] = useState<string>(
        moscowDateTime.getMinutes().toString(),
    );

    useEffect(() => {
        if (hours.length === 1) setHours('0' + hours.toString());
        if (minutes.length === 1) setMinutes('0' + minutes.toString());
    }, []);

    useEffect(() => {
        const correctTime = new Date(field.value);
        correctTime.setHours(parseInt(hours));
        correctTime.setMinutes(parseInt(minutes));
        field.onChange(correctTime);
    }, [hours, minutes]);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 992);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (isMobile && isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }, [isMobile, isOpen]);

    return (
        <StyledCustomDateTimePicker>
            <CalendraIcon onClick={() => setIsOpen(!isOpen)} />
            <DatePicker
                selected={field.value}
                onChange={(date: Date) => {
                    const newDate = new Date(date);
                    newDate.setHours(parseInt(hours));
                    newDate.setMinutes(parseInt(minutes));
                    field.onChange(newDate);
                }}
                dateFormat="dd MMMM yyyy"
                value={`${format(field.value, 'd MMMM', {
                    locale: ru,
                    timeZone: 'Europe/Moscow',
                })} ${hours}:${minutes}`}
                locale={ru}
                showTimeInput
                customTimeInput={
                    <CustomDateTimePikerTimeinputs
                        minutes={minutes}
                        hours={hours}
                        setHours={setHours}
                        setMinutes={setMinutes}
                        setIsOpen={setIsOpen}
                    />
                }
                open={isOpen}
                minDate={moscowDateTime}
                withPortal={isMobile && isOpen}
                onInputClick={() => setIsOpen(!isOpen)}
                onClickOutside={() => setIsOpen(false)}
            />
        </StyledCustomDateTimePicker>
    );
};

const StyledCustomDateTimePicker = styled.div`
    position: relative;

    & > svg {
        position: absolute;
        z-index: 2;
        top: 13px;
        right: 8px;
    }

    && .react-datepicker__portal {
        width: 100%;
        height: 100%;
    }

    && .react-datepicker-wrapper {
        width: 100%;
    }
    && .react-datepicker__day--today {
        border: 1px solid var(--color-primary-green);
        border-radius: 100%;
    }
    && .react-datepicker__input-container > input {
        padding: 13px 16px;
        border-radius: 4px;
        border: 1px solid var(--color-primary-green);
        width: 100%;
        transition: var(--transition);
        font-size: 16px;
        font-weight: 400;
        line-height: 130%;
        &::placeholder {
            color: var(--color-primary-gray);
            font-size: 16px;
            font-weight: 400;
            line-height: 130%;
        }
        &:hover {
            border-color: var(--color-primary-green-hover);
        }
    }

    && .react-datepicker {
        display: flex;
        width: 415px;
        font-family: 'Open Sans', sans-serif;
        font-size: 0.8rem;
        border: 0;
        border-radius: var(--radius);
        box-shadow: var(--shadow-main);
        @media (max-width: 992px) {
            flex-direction: column;
            width: 290px;
        }
    }
    && .react-datepicker-time__caption,
    .react-datepicker__triangle {
        display: none;
    }
    && .react-datepicker__input-time-container {
        padding: 0;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    && .react-datepicker-time__input-container {
        width: 100%;
        min-height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    && .react-datepicker-time__input {
        margin: 0;
    }

    && .react-datepicker__month-container {
        min-width: 290px;
    }
    && .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
        width: 2.1rem;
        line-height: 2.1rem;
    }

    && .react-datepicker__navigation--next {
        right: 123px;
        @media (max-width: 992px) {
            right: 2px;
        }
    }
    && .react-datepicker__day-name {
        font-size: 12px;
    }
    && .react-datepicker__day {
        font-size: 14px;
    }

    && .react-datepicker__day--selected,
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--selected,
    .react-datepicker__month-text--in-selecting-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--selected,
    .react-datepicker__quarter-text--in-selecting-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--selected,
    .react-datepicker__year-text--in-selecting-range,
    .react-datepicker__year-text--in-range {
        border-radius: 100%;
        background: var(--color-primary-green);
    }

    && .react-datepicker__day:hover,
    .react-datepicker__month-text:hover,
    .react-datepicker__quarter-text:hover,
    .react-datepicker__year-text:hover,
    .react-datepicker__day--keyboard-selected,
    .react-datepicker__month-text--keyboard-selected,
    .react-datepicker__quarter-text--keyboard-selected,
    .react-datepicker__year-text--keyboard-selected {
        border-radius: 100%;
        background: var(--color-primary-green-rgba);
    }

    && .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__month-read-view--down-arrow,
    .react-datepicker__month-year-read-view--down-arrow,
    .react-datepicker__navigation-icon::before {
        border-color: var(--color-primary-green);
        border-width: 1px 1px 0 0;
    }
    && .react-datepicker__header {
        background: var(--color-bg-first);
        border-bottom: none;
        padding-top: 45px;
    }
    && .react-datepicker__current-month {
        padding-bottom: 5px;
    }
    && .react-datepicker__navigation {
        top: 24px;
        @media (max-width: 992px) {
            top: 48px;
        }
    }
`;

export default CustomDateTimePicker;
