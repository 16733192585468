import { FC, memo } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

type StyledNavbarProps = {
    $isOpen: boolean;
};
type NavbarProps = {
    isOpen: boolean;
    burgerMenuClose: (isOpen: boolean) => void;
};

const Navbar: FC<NavbarProps> = ({ isOpen, burgerMenuClose }) => {
    return (
        <StyledNavbar $isOpen={isOpen}>
            <StyledLink onClick={() => burgerMenuClose(false)} to="/#rates">
                Тарифы
            </StyledLink>
            <StyledLink onClick={() => burgerMenuClose(false)} to="/#about">
                О нас
            </StyledLink>
            <StyledLink onClick={() => burgerMenuClose(false)} to="/#gratitude">
                Благодарности
            </StyledLink>
        </StyledNavbar>
    );
};

const StyledLink = styled(Link)`
    cursor: pointer;
    transition: var(--transition);
    &:hover {
        color: var(--color-primary-green-hover);
    }
`;

const StyledNavbar = styled.div<StyledNavbarProps>`
    display: flex;
    align-items: center;
    gap: 48px;
    flex-grow: 1;
    padding-left: ${({ $isOpen }) => (!$isOpen ? '100px' : '0')};
    @media (max-width: 992px) {
        ${({ $isOpen }) =>
            !$isOpen ? 'display: none;' : 'flex-direction: column;'};
        gap: 24px;
        order: 2;
    }
`;

export default memo(Navbar);
