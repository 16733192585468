import { FC, memo } from 'react';
import styled from 'styled-components';
import { ReactComponent as OrderPageBanner } from '../../../assets/images/banners/ban2.svg';
import { useAppSelector } from '../../../store/hooks';
import { Container } from '../../../styles/container.styled';
import { StyledSection } from '../../../styles/section.styled';
import CustomMap from '../../UI/CustomMap/CustomMap';
import OrderForm from '../../UI/OrderForm/OrderForm';
import PageTitle from '../../UI/PageTitle/PageTitle';

const OrderPage: FC = () => {
    const isRent = useAppSelector((state) => state.main.isRent);
    return (
        <StyledOrderPage>
            <StyledSection>
                <Container>
                    <PageTitle
                        title="Особенное такси"
                        subTitle="Комфортная и безопасная перевозка пассажиров-колясочников по Санкт-Петербургу и Ленинградской области"
                    />
                    <StyledOrderPageWrapper>
                        <OrderForm />
                        {!isRent ? <CustomMap /> : <StyledOrderPageBanner />}
                    </StyledOrderPageWrapper>
                </Container>
            </StyledSection>
        </StyledOrderPage>
    );
};

const StyledOrderPageBanner = memo(styled(OrderPageBanner)`
    display: block;
    width: 100%;
    height: auto;
    max-width: 524px;
`);

const StyledOrderPage = styled.div`
    min-height: 100vh;
    background: var(--color-bg-second);
    padding-top: 80px;
`;
const StyledOrderPageWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 40px;
    @media (max-width: 992px) {
        flex-direction: column;
    }
`;

export default OrderPage;
