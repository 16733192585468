export const SELECTOPTIONS = [
    {
        label: 'Легковой',
        value: 'passenger',
    },
    {
        label: 'Адаптированный',
        value: 'adapted',
    },
    {
        label: 'Аренда',
        value: 'rent',
    },
    // {
    //     label: 'Трансфер',
    //     value: 'transfer',
    // },
];
