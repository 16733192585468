import { FC, memo } from 'react';
import styled from 'styled-components';
import { ReactComponent as CrossIcon } from '../../../assets/icons/cross.svg';
import { ReactComponent as BurgerIcon } from '../../../assets/icons/menu.svg';

interface BurgerMenuProps {
    isOpen: boolean;
    onClickBurgerMenu: () => void;
}

type StyledBurgerMenuProps = {
    $isOpen: boolean;
};

const BurgerMenu: FC<BurgerMenuProps> = ({ isOpen, onClickBurgerMenu }) => {
    return (
        <StyledBurgerMenu $isOpen={isOpen} onClick={onClickBurgerMenu}>
            {isOpen ? <StyledCrossIcon /> : <StyledBurgerIcon />}
        </StyledBurgerMenu>
    );
};

const StyledCrossIcon = styled(CrossIcon)`
    animation: fade 0.3s linear;
`;
const StyledBurgerIcon = styled(BurgerIcon)`
    animation: fade 0.3s linear;
`;

const StyledBurgerMenu = styled.div<StyledBurgerMenuProps>`
    position: absolute;
    right: 0;
    top: ${({ $isOpen }) => ($isOpen ? '-25px' : '8px')};
    width: 26px;
    height: 26px;
    cursor: pointer;
    display: none;
    @media (max-width: 992px) {
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;

export default memo(BurgerMenu);
