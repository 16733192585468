import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useAppDispatch } from '../../store/hooks';
import { setInputsData } from '../../store/slices/mainSlice';
import { Container } from '../../styles/container.styled';
import { StyledSection } from '../../styles/section.styled';
import { StyledTitle } from '../../styles/title.styled';
import CustomButton from '../UI/CustomButton/CustomButton';
import Tooltip from '../UI/Tooltip/Tooltip';
import { tarifs } from './tarifs.constants';

const Tarifs: FC = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const goTo = (path: string, label: string, value: string) => {
        dispatch(setInputsData({ tarif: { label, value } }));

        navigate(path);
    };

    return (
        <StyledTarifs id="rates">
            <StyledSection>
                <Container>
                    <StyledTitle>Наши тарифы</StyledTitle>
                    <StyledSwiper>
                        <Swiper
                            modules={[Navigation, Pagination]}
                            loop={true}
                            slidesPerView={1}
                            navigation
                            centeredSlides={false}
                            pagination={{ clickable: true }}
                            breakpoints={{
                                640: {
                                    slidesPerView: 1,
                                    centeredSlides: false,
                                    spaceBetween: 20,
                                    loop: true,
                                },
                                768: {
                                    slidesPerView: 2,
                                    centeredSlides: false,
                                    spaceBetween: 32,
                                    loop: true,
                                },
                                992: {
                                    slidesPerView: 3,
                                    spaceBetween: 18,
                                    centeredSlides: true,
                                    loop: false,
                                    initialSlide: 1, // Начать со второго слайда
                                },
                            }}
                        >
                            {tarifs.map((item) => (
                                <SwiperSlide key={item.title}>
                                    <StyledTarifsSlide>
                                        <StyledTarifsSlideTop>
                                            {item.img}
                                            <h4>{item.title}</h4>
                                            <StyledTarifsSlideHtml
                                                dangerouslySetInnerHTML={{
                                                    __html: item.description,
                                                }}
                                            ></StyledTarifsSlideHtml>
                                        </StyledTarifsSlideTop>
                                        <StyledTarifsSlideBottom>
                                            <StyledTarifsSlidePrice>
                                                <Tooltip content={item.info}>
                                                    стоимость поездки
                                                </Tooltip>
                                                <small>{item.price}</small>
                                            </StyledTarifsSlidePrice>
                                            <CustomButton
                                                type="button"
                                                variant="primary"
                                                onClick={() =>
                                                    goTo(
                                                        '/order',
                                                        item.title,
                                                        item.key,
                                                    )
                                                }
                                            >
                                                заказать звонок
                                            </CustomButton>
                                        </StyledTarifsSlideBottom>
                                    </StyledTarifsSlide>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </StyledSwiper>
                </Container>
            </StyledSection>
        </StyledTarifs>
    );
};

const StyledSwiper = styled.div`
    && .swiper {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
        padding: 48px 15px 50px 15px;
        @media (max-width: 768px) {
            margin: 0 -15px 0 -15px;
            padding-bottom: 38px;
            padding-top: 24px;
        }
    }

    && .swiper-button-next:after,
    .swiper-button-prev:after {
        color: var(--color-primary-green);
        font-size: 20px;
    }
    && .swiper-button-prev {
        left: -8px;
        @media (max-width: 768px) {
            display: none;
        }
    }
    && .swiper-button-next {
        right: -8px;
        @media (max-width: 768px) {
            display: none;
        }
    }
    && .swiper-pagination-bullet-active {
        background: var(--color-primary-green);
    }
    && .swiper-slide-active {
        transform: scale(1.03);
        background: var(--color-bg-main);
        box-shadow: 4px 10px 20px 0px rgba(0, 100, 205, 0.25);
        @media (max-width: 992px) {
            transform: none;
            background: var(--color-bg-gray);
            box-shadow: var(--shadow-main);
        }
    }
`;

const StyledTarifsSlide = styled.div`
    height: 843px;
    padding: 32px 30px 48px 30px;
    background: var(--color-bg-gray);
    box-shadow: var(--shadow-main);
    border-radius: var(--radius);
    transition: transform 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    @media (max-width: 768px) {
        padding: 24px 22px 24px 22px;
        height: 780px;
    }

    svg {
        width: 100%;
        max-width: 285px;
        height: auto;
        margin-bottom: 32px;
    }
    h4 {
        text-align: center;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 130%;
        margin-bottom: 24px;
        @media (max-width: 768px) {
            font-size: 20px;
            padding-bottom: 20px;
        }
    }
`;

const StyledTarifsSlideTop = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;
const StyledTarifsSlideBottom = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
`;
const StyledTarifsSlidePrice = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;

    small {
        font-size: 22px;
        font-weight: 700;
        line-height: 130%;
        color: var(--color-primary-green);
    }
`;

const StyledTarifsSlideHtml = styled.div`
    ul {
        display: flex;
        flex-direction: column;
        gap: 16px;
        @media (max-width: 768px) {
            gap: 10px;
        }
        li {
            font-size: 16px;
            font-weight: 400;
            line-height: 130%;
            display: flex;
            align-items: baseline;
            gap: 10px;
            @media (max-width: 768px) {
                gap: 6;
                font-size: 15px;
            }
            &::before {
                content: '';
                display: block;
                width: 10px;
                height: 10px;
                background: var(--color-primary-blue);
                border-radius: 100%;
                flex: none;
                @media (max-width: 768px) {
                    width: 8px;
                    height: 8px;
                }
            }
        }
    }
`;

const StyledTarifs = styled.div`
    padding-top: 80px;
    @media (max-width: 768px) {
        padding-top: 32px;
    }
`;

export default Tarifs;
