import { FC } from 'react';
import styled from 'styled-components';
import ThxMessage from '../UI/ThxMessage/ThxMessage';
import ThxCallback from '../UI/ThxMessage/ThxCallback';
import ThxError from '../UI/ThxMessage/ThxError';
import { useAppSelector } from '../../store/hooks';
import ThxlimitKm from '../UI/ThxMessage/ThxlimitKm';

const Modal: FC = () => {
    const modalType = useAppSelector((state) => state.main.modalType);
    return (
        <StyledModal>
            {modalType === 'success' ? (
                <ThxMessage />
            ) : modalType === 'error' ? (
                <ThxError />
            ) : modalType === 'callback' ? (
                <ThxCallback />
            ) : modalType === 'limit' ? (
                <ThxlimitKm />
            ) : null}
        </StyledModal>
    );
};

const StyledModal = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    inset: 0;
    z-index: 1000;
    backdrop-filter: blur(5px);
`;

export default Modal;
