import { ReactComponent as Car1 } from '../../assets/images/cars/image1.svg';
import { ReactComponent as Car2 } from '../../assets/images/cars/image2.svg';
import { ReactComponent as Car3 } from '../../assets/images/cars/image3.svg';
// import { ReactComponent as Car4 } from '../../assets/images/cars/image4.svg';

export const tarifs = [
    {
        img: <Car1 />,
        title: 'Легковой',
        key: 'passenger',
        price: 'от 310 ₽',
        description: `
			<ul>
				<li>легковой автомобиль</li>
				<li>профессиональные водители с большим опытом перевозки пассажиров с ОВЗ</li>
				<li>помощь в погрузке и выгрузке кресла-коляски</li>
				<li>без спуска и подъема на этаж</li>
				<li>бесплатное ожидание — 10 минут</li>
			</ul>
		`,
        info: 'Указана ориентировочная стоимость поездки. Точную стоимость рассчитает оператор при заказе',
    },
    {
        img: <Car2 />,
        title: 'Адаптированный',
        key: 'adaptive',
        price: 'от 570 ₽',
        description: `
			<ul>
				<li>специализированный транспорт с электрогидроподъемником или аппарелью</li>
				<li>профессиональные водители, обученные работе с маломобильными пассажирами</li>
				<li>микроавтобус или минивэн</li>
				<li>подходит для 1-2 пассажиров на колясках</li>
				<li>помощь пассажиру при посадке 
				и высадке</li>
			</ul>
		`,
        info: 'Указана ориентировочная стоимость поездки. Точную стоимость рассчитает оператор при заказе',
    },
    {
        img: <Car3 />,
        title: 'Аренда',
        key: 'rent',
        price: '1 250 ₽/час',
        description: `
			<ul>
				<li>специализированный транспорт с электрогидроподъемником </li>
				<li>групповые перевозки</li>
				<li>подходит для групп до 10-12 человек</li>
				<li>профессиональные водители с большим опытом перевозки 
				пассажиров с ОВЗ</li>
				<li>минимальное время заказа — 4 часа</li>
			</ul>
		`,
        info: 'Указана минимальная стоимость поездки. Минимальное время заказа - 4 часа. Точную стоимость рассчитает оператор при заказе',
    },
    // {
    //     img: <Car4 />,
    //     title: 'Трансфер',
    //     price: 'от 1 200 ₽',
    //     description: `
    // 		<ul>
    // 			<li>трансфер в аэропорт и на вокзал и обратно</li>
    // 			<li>профессиональные водители, обученные работе с маломобильными пассажирами</li>
    // 			<li>100% гарантия подачи в срок</li>
    // 			<li>помощь в погрузке и разгрузке багажа</li>
    // 			<li>помощь пассажиру при посадке</li>
    // 		</ul>
    // 	`,
    //     info: 'Указана ориентировочная стоимость поездки. Точную стоимость рассчитает оператор при заказе',
    // },
];
