/* eslint-disable @typescript-eslint/no-unused-vars */
import { useMutation } from '@tanstack/react-query';
import { format, utcToZonedTime } from 'date-fns-tz';
import { ru } from 'date-fns/locale';
import { FC, useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as ChevroneBackIcon } from '../../../assets/icons/chevrone.svg';
import { fetchPost } from '../../../services/fetch';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
    setInputsData,
    setIsNight,
    setModalIsOpen,
    setModalType,
    setSetIsRent,
} from '../../../store/slices/mainSlice';
import { isOrganizationOpen } from '../../../utils/utils';
import Calcutator from '../Calcutator/Calcutator';
import CustomButton from '../CustomButton/CustomButton';
import CustomDadataInput from '../CustomDadataInput/CustomDadataInput';
import CustomDateTimePicker from '../CustomDateTimePiker/CustomDateTimePiker';
import CustomInput from '../CustomInput/CustomInput';
import CustomInputPhone from '../CustomInputPhone/CustomInputPhone';
import CustomSelect from '../CustomSelect/CustomSelect';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import { Inputs } from './OrderForm.types';
import { SELECTOPTIONS } from './orderForm.constant';

const moscowTimezone = 'Europe/Moscow';
const currentDateTime = new Date().setDate(new Date().getDate() + 1);
const moscowDateTime = utcToZonedTime(currentDateTime, moscowTimezone);

const OrderForm: FC = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { pointADadataRaw, pointBDadataRaw, tarif, name, phone, date, time } =
        useAppSelector((state) => state.main.inputsData);
    const { distance } = useAppSelector((state) => state.main.mapData);

    const validatePoints =
        pointADadataRaw?.value === '' || pointBDadataRaw?.value === ''
            ? true
            : false;

    const {
        register,
        handleSubmit,
        watch,
        // setValue,
        reset,
        control,
        formState: { errors },
    } = useForm<Inputs>({
        defaultValues: {
            name: '',
            phone: '',
            date: moscowDateTime,
            type: (tarif?.value !== '' && tarif) || SELECTOPTIONS[0],
        },
    });

    const { mutate: fetchLeed } = useMutation({
        mutationFn: () =>
            fetchPost(
                tarif?.value === 'rent'
                    ? {
                          name,
                          phone,
                          tarif: tarif?.value,
                      }
                    : {
                          distance,
                          name,
                          phone,
                          tarif: tarif?.value,
                          date,
                          time,
                          from: pointADadataRaw,
                          to: pointBDadataRaw,
                      },
                'lead',
            ),
        onSuccess: (result) => {
            if (result?.result?.success) {
                if (result?.result?.currentTime) {
                    if (!isOrganizationOpen(result?.result?.currentTime)) {
                        dispatch(setIsNight(true));
                    } else {
                        dispatch(setIsNight(false));
                    }
                }
                dispatch(setModalType('success'));
                dispatch(setModalIsOpen(true));
            } else {
                dispatch(setModalType('limit'));
                dispatch(setModalIsOpen(true));
            }
        },

        onError: (err) => {
            if (err instanceof Error) {
                console.log(err.message);
                dispatch(setModalType('error'));
                dispatch(setModalIsOpen(true));
            }
        },
    });

    const onSubmit: SubmitHandler<Inputs> = () => {
        fetchLeed();
    };

    useEffect(() => {
        if (watch('type').value === 'rent') {
            dispatch(setSetIsRent(true));
        } else {
            dispatch(setSetIsRent(false));
        }
        dispatch(
            setInputsData({
                name: watch('name'),
                phone: '+' + watch('phone').replace(/\D/g, ''),
                date: `${format(watch('date'), 'dd.MM.yyyy', {
                    locale: ru,
                    timeZone: 'Europe/Moscow',
                })}`,
                time: `${format(watch('date'), 'HH:mm', {
                    locale: ru,
                    timeZone: 'Europe/Moscow',
                })}`,
                tarif: watch('type'),
            }),
        );
    }, [watch('name'), watch('phone'), watch('date'), watch('type'), dispatch]);

    return (
        <StyledOrderForm onSubmit={handleSubmit(onSubmit)}>
            <StyledChevroneBack onClick={() => navigate('/')} />
            <StyledOrderFormInputsWrapper>
                <CustomInput
                    register={{
                        ...register('name', {
                            required: "Пожалуйста, заполните поле 'Имя'",
                        }),
                    }}
                    type={'text'}
                    placeholder="Ваше имя"
                    isError={errors.name ? true : false}
                />
                <CustomInputPhone
                    register={register('phone', {
                        validate: (value) =>
                            value.replace(/\D/g, '').length === 11 ||
                            'Некорректный номер телефона',
                    })}
                    type={'text'}
                    placeholder="Телефон"
                    isError={errors.phone ? true : false}
                />
                <Controller
                    name={'type'}
                    control={control}
                    render={({ field }) => {
                        return (
                            <CustomSelect
                                field={field}
                                options={SELECTOPTIONS}
                            />
                        );
                    }}
                />
                {tarif?.value !== 'rent' && (
                    <>
                        <CustomDadataInput
                            placeholder="Откуда"
                            name="from"
                            index={1}
                            isError={
                                pointADadataRaw?.value === '' ? true : false
                            }
                        />
                        <CustomDadataInput
                            placeholder="Куда"
                            name="to"
                            index={2}
                            isError={
                                pointBDadataRaw?.value === '' ? true : false
                            }
                        />
                        <Controller
                            name={'date'}
                            control={control}
                            render={({ field }) => {
                                return <CustomDateTimePicker field={field} />;
                            }}
                        />
                    </>
                )}
            </StyledOrderFormInputsWrapper>
            <StyledOrderFormButtonsWrapper>
                {tarif?.value !== 'rent' && <Calcutator />}

                <CustomButton
                    disabled={tarif?.value !== 'rent' && validatePoints}
                    type="submit"
                    variant="primary"
                >
                    заказать звонок
                </CustomButton>
            </StyledOrderFormButtonsWrapper>

            <ErrorMessage
                isError={
                    errors.name ||
                    errors.phone ||
                    (validatePoints && tarif?.value !== 'rent')
                }
            >
                {errors.name?.message ||
                    errors.phone?.message ||
                    (validatePoints &&
                        tarif?.value !== 'rent' &&
                        'Укажите адрес')}
            </ErrorMessage>
        </StyledOrderForm>
    );
};

const StyledChevroneBack = styled(ChevroneBackIcon)`
    position: absolute;
    left: 16px;
    top: 16px;
    cursor: pointer;
    @media (max-width: 992px) {
        top: 12px;
    }
`;

const StyledOrderForm = styled.form`
    position: relative;
    width: 100%;
    max-width: 386px;
    min-width: 260px;
    padding: 40px 48px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    box-shadow: var(--shadow-main);
    background: var(--color-bg-main);
    @media (max-width: 992px) {
        padding: 32px 20px;
        padding-top: 48px;
    }
`;

const StyledOrderFormInputsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

const StyledOrderFormButtonsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 32px;
`;

export default OrderForm;
