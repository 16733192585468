import { useMutation } from '@tanstack/react-query';
import { FC } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { fetchPost } from '../../../services/fetch';
import { useAppDispatch } from '../../../store/hooks';
import {
    setInputsData,
    setIsNight,
    setModalIsOpen,
    setModalType,
    setSetIsRent,
} from '../../../store/slices/mainSlice';
import { isOrganizationOpen } from '../../../utils/utils';
import CustomButton from '../CustomButton/CustomButton';
import CustomInput from '../CustomInput/CustomInput';
import CustomInputPhone from '../CustomInputPhone/CustomInputPhone';
import ErrorMessage from '../ErrorMessage/ErrorMessage';

type Inputs = {
    name: string;
    phone: string;
};

const FormCallback: FC = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {
        register,
        handleSubmit,
        watch,
        reset,
        formState: { errors },
    } = useForm<Inputs>();

    const { mutate: fetchLeed } = useMutation({
        mutationFn: () =>
            fetchPost(
                {
                    name: watch('name'),
                    phone: '+' + watch('phone').replace(/\D/g, ''),
                },
                'lead',
            ),
        onSuccess: (result) => {
            if (result?.result?.success) {
                dispatch(
                    setInputsData({
                        name: watch('name'),
                        phone: watch('phone'),
                    }),
                );
                if (result?.result?.currentTime) {
                    if (!isOrganizationOpen(result?.result?.currentTime)) {
                        dispatch(setIsNight(true));
                    } else {
                        dispatch(setIsNight(false));
                    }
                }
                reset();
                dispatch(setModalType('callback'));
                dispatch(setModalIsOpen(true));
            }
            //Добавить плашку
        },

        onError: (err) => {
            if (err instanceof Error) {
                console.log(err.message);
                dispatch(setModalType('error'));
                dispatch(setModalIsOpen(true));
            }
        },
    });

    const onSubmit: SubmitHandler<Inputs> = () => {
        fetchLeed();
    };

    return (
        <StyledFormCallback onSubmit={handleSubmit(onSubmit)}>
            <StyledFormCallbackInputsWrapper>
                <CustomInput
                    register={{
                        ...register('name', {
                            required: "Пожалуйста, заполните поле 'Имя'",
                        }),
                    }}
                    type={'text'}
                    placeholder="Ваше имя"
                    isError={errors.name ? true : false}
                />
                <CustomInputPhone
                    register={register('phone', {
                        validate: (value) =>
                            value.replace(/\D/g, '').length === 11 ||
                            'Некорректный номер телефона',
                    })}
                    type={'text'}
                    placeholder="Телефон"
                    isError={errors.phone ? true : false}
                />
            </StyledFormCallbackInputsWrapper>
            <StyledFormCallbackButtonsWrapper>
                <CustomButton
                    type="submit"
                    variant="primary"
                    onClick={() => console.log('click')}
                >
                    заказать звонок
                </CustomButton>
                <CustomButton
                    type="button"
                    variant="normal"
                    onClick={() => {
                        dispatch(setSetIsRent(false));
                        navigate('/order');
                    }}
                >
                    Рассчитать стоимость
                </CustomButton>
            </StyledFormCallbackButtonsWrapper>
            <ErrorMessage isError={errors.name || errors.phone ? true : false}>
                {errors.name?.message || errors.phone?.message}
            </ErrorMessage>
        </StyledFormCallback>
    );
};

const StyledFormCallback = styled.form`
    width: 100%;
    max-width: 386px;
    min-width: 260px;
    padding: 40px 48px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    box-shadow: var(--shadow-main);
    background: var(--color-bg-main);
    @media (max-width: 992px) {
        padding: 32px 20px;
    }
`;
const StyledFormCallbackInputsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;
const StyledFormCallbackButtonsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

export default FormCallback;
