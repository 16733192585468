import { FC } from 'react';
import styled from 'styled-components';
import { Container } from '../../styles/container.styled';
import FooterRight from './FooterRight/FooterRight';
import FooterNav from './FooterNav/FooterNav';
import FooterLeft from './FooterLeft/FooterLeft';

const Footer: FC = () => {
    return (
        <StyledFooter>
            <Container>
                <StyledFooterWrapper>
                    <FooterLeft />
                    <FooterNav />
                    <FooterRight />
                </StyledFooterWrapper>
            </Container>
        </StyledFooter>
    );
};

const StyledFooter = styled.footer`
    flex: 0 0 auto;
    background: var(--color-primary-blue);
    padding-top: 24px;
    padding-bottom: 56px;
    @media (max-width: 992px) {
        padding-top: 40px;
        padding-bottom: 40px;
    }
`;
const StyledFooterWrapper = styled.footer`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 24px;
    @media (max-width: 992px) {
        flex-direction: column;
        align-items: center;
    }
`;

export default Footer;
