import { FC } from 'react';
import { Link } from 'react-scroll';
import styled from 'styled-components';
import { ReactComponent as VkIconWhite } from '../../../assets/icons/vk-white.svg';
import PhoneLink from '../../UI/PhoneLink/PhoneLink';
import { StyledFooterLeftComment } from '../FooterLeft/FooterLeft';

const FooterRight: FC = () => {
    return (
        <StyledFooterRight>
            <StyledFooterRightMobileWrapper>
                <PhoneLink
                    phone={'8 800 533-79-17'}
                    icon={false}
                    onlyIconMobile={false}
                    color={'var(--color-text-white)'}
                />
                <StyledFooterLeftComment>
                    Группа компаний Медицинский центр «XXI век» © 2023
                </StyledFooterLeftComment>
            </StyledFooterRightMobileWrapper>
            <StyledLink to="form" smooth={true} offset={-70} duration={500}>
                Заказать звонок
            </StyledLink>
            <StyledVkIconWhite
                href="https://vk.com/medtaxi"
                target="_blank"
                rel="noopener noreferrer"
            >
                <VkIconWhite />
            </StyledVkIconWhite>
        </StyledFooterRight>
    );
};

const StyledLink = styled(Link)`
    cursor: pointer;
    width: 100%;
    padding: 13px 21px;
    border-radius: var(--radius);
    transition: var(--transition);
    text-align: center;
    font-size: 15px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.3px;
    text-transform: uppercase;
    background: var(--color-text-white);
    color: var(--color-primary-blue);
    box-shadow: var(--shadow-second);
    border: 1px solid var(--color-primary-blue);
    &:hover {
        border: 1px solid var(--color-text-white);
        background: var(--color-primary-blue);
        color: var(--color-text-white);
    }
`;

const StyledFooterRightMobileWrapper = styled.div`
    @media (max-width: 992px) {
        order: 3;
    }
    div {
        display: none;
        @media (max-width: 992px) {
            display: flex;
            @media (max-width: 992px) {
                font-size: 14px;
                max-width: 220px;
            }
        }
    }
    a {
        @media (max-width: 992px) {
            display: none;
        }
    }
`;

const StyledVkIconWhite = styled.a`
    cursor: pointer;
    order: 1;
`;

const StyledFooterRight = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 24px;
    flex: none;
    @media (max-width: 992px) {
        flex-direction: column;
        align-items: center;
        padding-top: 50px;
    }
`;

export default FooterRight;
