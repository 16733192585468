import { useMutation } from '@tanstack/react-query';
import { FC, useEffect } from 'react';
import styled from 'styled-components';
import { fetchPost } from '../../../services/fetch';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
    setModalIsOpen,
    setModalType,
    setPrice,
} from '../../../store/slices/mainSlice';
import Tooltip from '../Tooltip/Tooltip';

interface ICalcutatorProps {}

const Calcutator: FC<ICalcutatorProps> = () => {
    const dispatch = useAppDispatch();
    const { pointADadataRaw, pointBDadataRaw, tarif, date, time } =
        useAppSelector((state) => state.main.inputsData);
    const { distance, duration } = useAppSelector(
        (state) => state.main.mapData,
    );
    const price = useAppSelector((state) => state.main.price);

    const validatePoints =
        pointADadataRaw?.value === '' || pointBDadataRaw?.value === ''
            ? true
            : false;

    const { mutate: calcPrice } = useMutation({
        mutationFn: () =>
            fetchPost(
                {
                    distance,
                    duration,
                    tarif: tarif?.value,
                    date,
                    time,
                    from: pointADadataRaw,
                    to: pointBDadataRaw,
                },
                'calc',
            ),
        onSuccess: (result) => {
            if (result.result.success) {
                dispatch(setPrice(result.result.price));
            } else {
                dispatch(setPrice('-1'));
            }
        },

        onError: (err) => {
            if (err instanceof Error) {
                console.log(err.message);
                dispatch(setModalType('error'));
                dispatch(setModalIsOpen(true));
            }
        },
    });

    useEffect(() => {
        if (!validatePoints) {
            calcPrice();
        } else {
            dispatch(setPrice('0'));
        }
    }, [distance, tarif, date, time]);

    return (
        <StyledCalcutator>
            {price !== '' && !validatePoints && (
                <StyledPriceWrapper>
                    {price !== '-1' ? (
                        <>
                            <Tooltip content="Указана ориентировочная стоимость поездки. Точную стоимость рассчитает оператор при заказе">
                                ориентировочная стоимость поездки
                            </Tooltip>
                            <small>{`от ${price} ₽`}</small>
                        </>
                    ) : (
                        <p>
                            Расстояние маршрута превышает 100 км. Стоимость
                            будет рассчитана индивидуально
                        </p>
                    )}
                </StyledPriceWrapper>
            )}

            {/* <CustomButton
                disabled={validatePoints}
                type="button"
                variant="normal"
                onClick={() => calcPrice()}
            >
                рассчитать
            </CustomButton> */}
        </StyledCalcutator>
    );
};

const StyledPriceWrapper = styled.div`
    text-align: center;
    p {
        display: block;
        padding-top: 10px;
    }
    small {
        font-size: 22px;
        font-weight: 700;
        line-height: 130%;
        color: var(--color-primary-green);
        padding-top: 10px;
        display: block;
    }
`;

const StyledCalcutator = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
`;

export default Calcutator;
