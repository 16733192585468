import { FC } from 'react';
import styled from 'styled-components';
import { ReactComponent as FailureIcon } from '../../../assets/icons/failure.svg';
import { useAppDispatch } from '../../../store/hooks';
import { setModalIsOpen } from '../../../store/slices/mainSlice';
import CustomButton from '../CustomButton/CustomButton';

const ThxError: FC = () => {
    const dispatch = useAppDispatch();

    const onClickClose = () => {
        dispatch(setModalIsOpen(false));
    };
    return (
        <StyledThxError>
            <FailureIcon />
            <h4>Что-то пошло не так, попробуйте позже</h4>
            <CustomButton
                onClick={onClickClose}
                variant="primary"
                type="button"
            >
                Понятно
            </CustomButton>
        </StyledThxError>
    );
};

const StyledThxError = styled.div`
    max-width: 451px;
    padding: 64px;
    background: var(--color-bg-main);
    border-radius: var(--radius);
    box-shadow: var(--shadow-main);
    display: flex;
    flex-direction: column;

    @media (max-width: 640px) {
        padding: 32px 16px;
    }
    svg {
        align-self: center;
        margin-bottom: 32px;
        flex: none;
    }

    h4 {
        text-align: center;
        font-size: 22px;
        font-weight: 700;
        line-height: normal;
        margin-bottom: 48px;
        @media (max-width: 640px) {
            margin-bottom: 32px;
            font-size: 18px;
        }
    }
`;

export default ThxError;
