import { FC, memo } from 'react';
import styled from 'styled-components';

type CustomButtonProps = {
    type: 'button' | 'submit' | 'reset';
    onClick?: () => void;
    children: string;
    variant: 'primary' | 'normal' | 'footer';
    disabled?: boolean;
    isNight?: boolean;
};

const CustomButton: FC<CustomButtonProps> = ({
    onClick,
    children,
    variant,
    type,
    disabled,
    isNight,
}) => {
    return (
        <StyledCustomButton
            disabled={disabled}
            type={type}
            $variant={variant}
            onClick={onClick}
            $isNight={isNight}
        >
            {children}
        </StyledCustomButton>
    );
};

const StyledCustomButton = memo(styled.button<{
    $variant: 'primary' | 'normal' | 'footer';
    $isNight?: boolean;
}>`
    width: 100%;
    padding: 13px 21px;
    border-radius: var(--radius);
    background: ${({ $variant }) =>
        $variant === 'primary'
            ? 'var(--color-primary-green)'
            : 'var(--color-text-white)'};
    box-shadow: ${({ $variant }) =>
        $variant === 'footer' ? 'var(--shadow-second)' : 'var(--shadow-main)'};
    box-shadow: ${({ $isNight }) => $isNight && 'var(--shadow-second)'};
    transition: var(--transition);
    text-align: center;
    font-size: 15px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.3px;
    text-transform: uppercase;
    outline: 1px solid
        ${({ $variant }) =>
            $variant === 'normal'
                ? 'var(--color-primary-green-hover)'
                : 'var(--color-text-white)'};
    color: ${({ $variant }) =>
        $variant === 'normal'
            ? 'var(--color-primary-green)'
            : $variant === 'footer'
            ? 'var(--color-primary-blue)'
            : 'var(--color-text-white)'};
    &:hover {
        background: ${({ $variant }) =>
            $variant === 'normal'
                ? 'var(--color-primary-green)'
                : 'var(--color-text-white)'};
        outline: 1px solid
            ${({ $variant }) =>
                $variant === 'primary'
                    ? 'var(--color-primary-green)'
                    : 'var(--color-text-white)'};

        color: ${({ $variant }) =>
            $variant === 'primary'
                ? 'var(--color-primary-green)'
                : $variant === 'footer'
                ? 'var(--color-primary-blue)'
                : 'var(--color-text-white)'};
    }
`);

export default CustomButton;
