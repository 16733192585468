import { FC, memo } from 'react';
import styled from 'styled-components';
import { LogoProps, StyledLogoProps } from './Logo.types';
import { Link } from 'react-router-dom';

const Logo: FC<LogoProps> = ({ type, className, isOpen, children }) => {
    return (
        <StyledLogo
            to={'/'}
            className={className}
            $type={type}
            $isOpen={isOpen}
        >
            {children}
        </StyledLogo>
    );
};

const StyledLogo = styled(Link)<StyledLogoProps>`
    display: block;
    width: ${({ $type }) => ($type === 'colored' ? '78px' : '95px')};
    height: ${({ $type }) => ($type === 'colored' ? '71px' : '88px')};
    @media (max-width: 992px) {
        width: ${({ $type, $isOpen }) =>
            $type === 'colored' ? ($isOpen ? '78px' : '46px') : '64px'};
        height: ${({ $type, $isOpen }) =>
            $type === 'colored' ? ($isOpen ? '71px' : '42px') : '59px'};
    }
    svg {
        width: 100%;
        height: 100%;
    }
`;

export default memo(Logo);
