/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, memo } from 'react';
import InputMask from 'react-input-mask';
import styled from 'styled-components';

interface ICustomInputPhoneProps {
    register: any;
    placeholder?: string;
    type?: string;
    isError?: boolean;
    // onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

const CustomInputPhone: FC<ICustomInputPhoneProps> = ({
    register,
    placeholder,
    // onChange,
    type,
    isError,
}) => {
    return (
        <StyledInputMask
            $isError={isError}
            {...register}
            placeholder={placeholder}
            mask="+7 (999) 999-99-99"
            type={type}
            maskChar="_"

            // onChange={onChange}
        ></StyledInputMask>
    );
};
const StyledInputMask = styled(InputMask)<{ $isError: boolean }>`
    padding: 13px 16px;
    border-radius: 4px;
    border: 1px solid
        ${({ $isError }) =>
            $isError ? 'var(--color-error)' : 'var(--color-primary-green)'};
    width: 100%;
    transition: var(--transition);
    font-size: 16px;
    font-weight: 400;
    line-height: 130%;
    color: ${({ $isError }) =>
        $isError ? 'var(--color-error)' : 'var(--color-text-main)'};
    &::placeholder {
        color: ${({ $isError }) =>
            $isError ? 'var(--color-error)' : 'var(--color-primary-gray)'};
        font-size: 16px;
        font-weight: 400;
        line-height: 130%;
    }
    &:hover {
        border-color: ${({ $isError }) =>
            $isError
                ? 'var(--color-error)'
                : 'var(--color-primary-green-hover)'};
    }
`;

export default memo(CustomInputPhone);
