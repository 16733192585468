import { FC } from 'react';
import styled from 'styled-components';
import licensePdf from '../../../assets/images/pdf/license.pdf';

const FooterNav: FC = () => {
    return (
        <StyledFooterNav>
            <a
                href="https://tlb21vek.ru/ "
                target="_blank"
                rel="noopener noreferrer"
            >
                Транспортировка лежачих больных
            </a>
            <a
                href="https://taxi-deti.ru/ "
                target="_blank"
                rel="noopener noreferrer"
            >
                Детское такси
            </a>
            <a href={licensePdf} target="_blank" rel="noopener noreferrer">
                Лицензия
            </a>
        </StyledFooterNav>
    );
};

const StyledFooterNav = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 32px 80px;
    max-width: 572px;

    @media (max-width: 992px) {
        flex-direction: column-reverse;
        align-items: center;
        text-align: center;
    }
    a {
        color: var(--color-text-white);
        cursor: pointer;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        transition: var(--transition);
        align-items: center;
        @media (max-width: 992px) {
            font-size: 16px;
        }

        &:hover {
            opacity: 0.7;
        }
        &:after {
            content: '';
            display: block;
            height: 0.5px;
            width: 100%;
            background: var(--color-text-white);
        }
    }
`;

export default FooterNav;
