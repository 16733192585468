import { FC } from 'react';
import styled from 'styled-components';
import Tippy from '@tippyjs/react';
import { ReactComponent as InfoIcon } from '../../../assets/icons/information.svg';

interface TooltipProps {
    content: string;
    children: React.ReactNode;
}

const Tooltip: FC<TooltipProps> = ({ content, children }) => {
    return (
        <StyledTooltip>
            {children}
            <Tippy
                content={<StyledTooltipContent>{content}</StyledTooltipContent>}
                delay={100}
                placement="right"
            >
                <StyledInfoIcon />
            </Tippy>
        </StyledTooltip>
    );
};

const StyledTooltip = styled.div`
    display: inline-block !important;
    font-size: 15px;
    font-weight: 300;
    line-height: 130%;
    text-align: center;
    gap: 4px;
`;
const StyledTooltipContent = styled.div`
    padding: 10px;
    background: var(--color-bg-main);
    box-shadow: var(--shadow-main);
    font-size: 14px;
    font-weight: 300;
    line-height: 150%; /* 21px */
    letter-spacing: 0.28px;
    max-width: 273px;
`;
const StyledInfoIcon = styled(InfoIcon)`
    vertical-align: middle;
    padding-left: 4px;
    max-width: 18px;
    max-height: 18px;
    width: 18px !important;
    margin-bottom: 0 !important;
    cursor: pointer;
`;

export default Tooltip;
