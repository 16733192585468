import ReactDOM from 'react-dom/client';
import {
    createBrowserRouter,
    Navigate,
    RouterProvider,
} from 'react-router-dom';
import App from './App';
import './index.css';
import OrderPage from './components/pages/OrderPage/OrderPage';
import MainPage from './components/pages/MainPage/MainPage';
import { Provider } from 'react-redux';
import store from './store/store';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const queryClient = new QueryClient();

const router = createBrowserRouter([
    {
        path: '/',
        element: <App />,
        children: [
            {
                index: true,
                element: <MainPage />,
            },
            {
                path: 'order',
                element: <OrderPage />,
            },
            {
                path: '*',
                element: <Navigate to="/" replace={true} />,
            },
        ],
    },
]);

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement,
);
root.render(
    <QueryClientProvider client={queryClient}>
        <Provider store={store}>
            <RouterProvider router={router} />
        </Provider>
    </QueryClientProvider>,
);
