import { FC, memo } from 'react';
import styled from 'styled-components';
import { ReactComponent as PhoneIcon } from '../../../assets/icons/phone.svg';
import { PhoneLinkProps, PhoneLinkPropsType } from './PhoneLink.types';

const PhoneLink: FC<PhoneLinkProps> = ({
    phone,
    icon,
    onlyIconMobile,
    color,
    isOpen,
}) => {
    const formatedPhone = phone.replace(/\D/g, '').replace(/^(\d+)/, 'tel:$1');
    return (
        <StyledPhoneLink
            href={formatedPhone}
            $color={color}
            $onlyIconMobile={onlyIconMobile}
            $isOpen={isOpen}
        >
            {icon && <PhoneIcon />}
            <span>{phone}</span>
        </StyledPhoneLink>
    );
};

const StyledPhoneLink = styled.a<PhoneLinkPropsType>`
    display: flex;
    align-items: center;
    gap: 7px;

    @media (max-width: 992px) {
        order: 1;
    }
    & svg path {
        transition: var(--transition);
    }
    & svg {
        width: 28px;
        height: 28px;
        @media (max-width: 992px) {
            width: 20px;
            height: 20px;
            ${({ $onlyIconMobile }) =>
                $onlyIconMobile && 'margin-right: 50px;'};
            ${({ $isOpen }) => $isOpen && 'display: none;'};
        }
    }
    span {
        cursor: pointer;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        position: relative;
        transition: var(--transition);
        color: ${({ $color }) => ($color ? $color : 'var(--color-text)')};
        @media (max-width: 992px) {
            ${({ $onlyIconMobile, $isOpen }) =>
                $onlyIconMobile && !$isOpen && 'display: none;'};
        }
        &::after {
            content: '';
            position: absolute;
            bottom: 2px;
            display: block;
            height: 1px;
            width: 100%;
            background: ${({ $color }) =>
                $color ? $color : 'var(--color-text)'};
            transition: var(--transition);
            @media (max-width: 992px) {
                ${({ $onlyIconMobile, $isOpen }) =>
                    $onlyIconMobile && $isOpen && 'display: none;'};
            }
        }
    }
    &:hover {
        & span::after {
            background: ${({ $color }) =>
                $color ? $color : 'var(--color-primary-green-hover)'};
        }
        & svg path {
            fill: var(--color-primary-green-hover);
        }
        span {
            color: ${({ $color }) =>
                $color ? $color : 'var(--color-primary-green-hover)'};
            ${({ $color }) => $color && 'opacity: 0.7;'}
        }
    }
`;

export default memo(PhoneLink);
