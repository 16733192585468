import { FC, useState } from 'react';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Pagination } from 'swiper/modules';
import { aboutSlides } from './aboutSlider.contants';
import FsLightbox from 'fslightbox-react';
import { nanoid } from '@reduxjs/toolkit';

const AboutSlider: FC = () => {
    const [toggler, setToggler] = useState<boolean>(false);
    const [selectedImageIndex, setSelectedImageIndex] = useState<number>(0);
    return (
        <>
            <StyledAboutSwiper>
                <Swiper
                    className={'about'}
                    modules={[Pagination]}
                    spaceBetween={30}
                    slidesPerView={1}
                    pagination={{ clickable: true }}
                >
                    {aboutSlides.map((im) => (
                        <SwiperSlide key={nanoid()}>
                            <StyledAboutSwiperSlide
                                onClick={() => {
                                    setSelectedImageIndex(
                                        aboutSlides.indexOf(im),
                                    );
                                    setToggler(!toggler);
                                }}
                            >
                                <img
                                    src={im}
                                    width="100%"
                                    height="auto"
                                    alt="Image"
                                />
                            </StyledAboutSwiperSlide>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </StyledAboutSwiper>
            <FsLightbox
                toggler={toggler}
                sources={aboutSlides}
                sourceIndex={selectedImageIndex}
            />
        </>
    );
};

const StyledAboutSwiper = styled.div`
    width: 100%;
    max-width: 540px;
    height: auto;
    && .swiper {
        flex: 1;
        width: 100%;
        max-width: 540px;
        height: auto;
        padding: 0 15px 40px 15px;
    }

    && .swiper-pagination-bullet-active {
        background: var(--color-primary-green);
    }
    && .swiper-slide {
        width: 100% !important;
        max-width: 540px;
        min-width: 290px;
        height: auto;
    }
`;
const StyledAboutSwiperSlide = styled.div`
    box-shadow: var(--shadow-main);
    cursor: pointer;
    border-radius: var(--radius);

    img {
        border-radius: var(--radius);
        box-shadow: var(--shadow-main);
        width: 100% !important;
        max-width: 540px;
        min-width: 290px;
        height: auto;
    }
`;

export default AboutSlider;
