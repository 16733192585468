/* eslint-disable @typescript-eslint/no-explicit-any */
export const divideAndRound = (number: number) => {
    if (number) {
        const devideNumber = number / 1000;
        let roundedNumber: number;

        if (devideNumber - Math.floor(devideNumber) >= 0.5) {
            roundedNumber = Math.ceil(devideNumber);
        } else {
            roundedNumber = Math.floor(devideNumber);
        }

        return roundedNumber;
    }
};
export const roundNumber = (num: number) => {
    if (typeof num === 'number') {
        const roundedNum = Math.round(num);
        const decimalPart = num - roundedNum;

        if (decimalPart > 0.5) {
            return roundedNum + 1;
        } else if (decimalPart < -0.5) {
            return roundedNum - 1;
        } else {
            return roundedNum;
        }
    }

    return 0;
};

export const getLocation = () => {
    if (navigator.geolocation) {
        // Запрашиваем текущие координаты пользователя
        navigator.geolocation.getCurrentPosition(function (position) {
            const latitude = position.coords.latitude;
            const longitude = position.coords.longitude;

            console.log('Широта: ' + latitude);
            console.log('Долгота: ' + longitude);
        });
    } else {
        console.log('Геолокация не поддерживается вашим браузером');
    }
};

export const isOrganizationOpen = (currentTime: string) => {
    const startTime = '07:30:00';
    const endTime = '21:01:00';

    const currentHour = parseInt(currentTime.split(':')[0]);
    const currentMinute = parseInt(currentTime.split(':')[1]);
    const currentSecond = parseInt(currentTime.split(':')[2]);

    const startHour = parseInt(startTime.split(':')[0]);
    const startMinute = parseInt(startTime.split(':')[1]);
    const startSecond = parseInt(startTime.split(':')[2]);

    const endHour = parseInt(endTime.split(':')[0]);
    const endMinute = parseInt(endTime.split(':')[1]);
    const endSecond = parseInt(endTime.split(':')[2]);

    if (
        (currentHour > startHour && currentHour < endHour) ||
        (currentHour === startHour &&
            currentMinute >= startMinute &&
            currentSecond >= startSecond) ||
        (currentHour === endHour &&
            currentMinute <= endMinute &&
            currentSecond <= endSecond)
    ) {
        return true;
    } else {
        return false;
    }
};
