/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { nanoid } from '@reduxjs/toolkit';
import { useMutation } from '@tanstack/react-query';
import {
    ChangeEvent,
    FC,
    memo,
    useEffect,
    useLayoutEffect,
    useRef,
} from 'react';
import styled from 'styled-components';
import { useDebounce, useLocalStorage } from 'usehooks-ts';
import { ReactComponent as LocationIcon } from '../../../assets/icons/location.svg';
import { fetchPost } from '../../../services/fetch';
import { useAppDispatch } from '../../../store/hooks';
import {
    setInputsData,
    setMapDataFromDadataInputs,
} from '../../../store/slices/mainSlice';
import {
    DadataRawType,
    ICustomDadataInputProps,
} from './CustomDadataInput.types';

const MIN_TEXTAREA_HEIGHT = 48;

const CustomDadataInput: FC<ICustomDadataInputProps> = ({
    placeholder,
    name,
    isError,
    index,
}) => {
    const dispatch = useAppDispatch();

    const [value, setValue] = useLocalStorage<string>(name + 'value', '');
    const debouncedValue = useDebounce<string>(value, 1000);
    const [rawValue, setRawValue] = useLocalStorage<DadataRawType | null>(
        name + 'rawValue',
        null,
    );
    const [stopSearch, setStopSearch] = useLocalStorage<boolean>(
        name + 'stopSearch',
        false,
    );

    const textareaRef = useRef<HTMLTextAreaElement>(null);

    useLayoutEffect(() => {
        // Reset height - important to shrink on delete
        if (textareaRef.current) {
            if (value !== '') {
                textareaRef.current.style.height = 'inherit';
                // Set height
                textareaRef.current.style.height = `${Math.max(
                    textareaRef.current.scrollHeight,
                    MIN_TEXTAREA_HEIGHT,
                )}px`;
            } else {
                textareaRef.current.style.height = '47px';
            }
        }
    }, [value]);

    const {
        mutate: searchQuery,
        isSuccess,
        data,
        isPending,
    } = useMutation({
        mutationFn: () => fetchPost({ search: debouncedValue }, 'geo'),
        onSuccess: (result) => {
            const resultArrays = result.result;
            if (resultArrays.length !== 0) {
                const findRaw = resultArrays.filter(
                    (f: DadataRawType) => f.value === rawValue?.value,
                );
                if (findRaw.length !== 0) {
                    console.log(findRaw);
                    if (
                        findRaw[0].data.house ||
                        findRaw[0].data.street_type === 'м'
                    ) {
                        setStopSearch(true);
                    } else {
                        setStopSearch(false);
                    }
                }
            } else {
                const re =
                    name === 'from'
                        ? {
                              pointADadataRaw: {
                                  data: {},
                                  value: '',
                                  unrestricted_value: '',
                              },
                          }
                        : {
                              pointBDadataRaw: {
                                  data: {},
                                  value: '',
                                  unrestricted_value: '',
                              },
                          };
                dispatch(
                    setMapDataFromDadataInputs(
                        name === 'from'
                            ? {
                                  pointA: [],
                              }
                            : { pointB: [] },
                    ),
                );
                dispatch(setInputsData(re));
            }
            console.log(result.result);
        },

        onError: (err) => {
            if (err instanceof Error) {
                console.log(err.message);
            }
        },
    });

    const onSelectIitem = (dadataRaw: DadataRawType) => {
        setValue(dadataRaw.value);
        setRawValue(dadataRaw);
        if (dadataRaw) {
            const { geo_lat, geo_lon } = dadataRaw.data;

            if (name === 'from') {
                dispatch(
                    setMapDataFromDadataInputs({
                        pointA: [parseFloat(geo_lat), parseFloat(geo_lon)],
                    }),
                );
                dispatch(
                    setInputsData({
                        pointADadataRaw: dadataRaw,
                    }),
                );
            } else if (name === 'to') {
                dispatch(
                    setMapDataFromDadataInputs({
                        pointB: [parseFloat(geo_lat), parseFloat(geo_lon)],
                    }),
                );
                dispatch(
                    setInputsData({
                        pointBDadataRaw: dadataRaw,
                    }),
                );
            } else return;
        }
    };

    const onChangeValue = (e: ChangeEvent<HTMLTextAreaElement>) => {
        const inputValue = e.target.value;
        if (inputValue === '') {
            if (name === 'from') {
                dispatch(
                    setMapDataFromDadataInputs({
                        pointA: [],
                    }),
                );
                dispatch(
                    setInputsData({
                        pointADadataRaw: {
                            data: {},
                            value: '',
                            unrestricted_value: '',
                        },
                    }),
                );
            } else if (name === 'to') {
                dispatch(
                    setMapDataFromDadataInputs({
                        pointB: [],
                    }),
                );
                dispatch(
                    setInputsData({
                        pointBDadataRaw: {
                            data: {},
                            value: '',
                            unrestricted_value: '',
                        },
                    }),
                );
            } else return;
        }
        setValue(inputValue);
    };

    useEffect(() => {
        if (value !== '') {
            searchQuery();
        }
    }, [debouncedValue]);
    useEffect(() => {
        if (value !== rawValue?.value) {
            setRawValue({
                data: {},
                value: '',
                unrestricted_value: '',
            });
            setStopSearch(false);
        } else {
            setStopSearch(false);
        }
    }, [value]);

    return (
        <StyledCustomDadataInput $isError={isError}>
            <textarea
                ref={textareaRef}
                placeholder={placeholder}
                name={name}
                value={value}
                onInput={onChangeValue}
                style={{
                    minHeight: MIN_TEXTAREA_HEIGHT,
                }}
            />
            <StyledLocationIcon $isError={isError} />

            {data?.result.length !== 0 &&
                !isPending &&
                isSuccess &&
                !stopSearch &&
                value !== '' && ( // Add rawValue?.value !== value check (
                    <StyledCustomDadataInputList $zidx={index}>
                        {data.result.map((item: any) => (
                            <StyledCustomDadataInputListItem
                                key={nanoid()}
                                onClick={() => onSelectIitem(item)}
                            >
                                {item.value}
                            </StyledCustomDadataInputListItem>
                        ))}
                    </StyledCustomDadataInputList>
                )}
        </StyledCustomDadataInput>
    );
};

const StyledLocationIcon = styled(LocationIcon)<{ $isError?: boolean }>`
    path {
        stroke: ${({ $isError }) =>
            $isError ? 'var(--color-error)' : 'var(--color-primary-green)'};
    }
`;

const StyledCustomDadataInput = styled.div<{ $isError?: boolean }>`
    position: relative;
    color: ${({ $isError }) =>
        $isError ? 'var(--color-error)' : 'var(--color-text-main)'};
    textarea {
        padding: 13px 37px 12px 16px;
        border-radius: 4px;
        border: 1px solid
            ${({ $isError }) =>
                $isError ? 'var(--color-error)' : 'var(--color-primary-green)'};
        width: 100%;
        transition: var(--transition);
        font-size: 16px;
        font-weight: 400;
        line-height: 130%;
        color: ${({ $isError }) =>
            $isError ? 'var(--color-error)' : 'var(--color-text-main)'};
        &::placeholder {
            color: ${({ $isError }) =>
                $isError ? 'var(--color-error)' : 'var(--color-primary-gray)'};
            font-size: 16px;
            font-weight: 400;
            line-height: 130%;
        }
        &:hover {
            border-color: ${({ $isError }) =>
                $isError
                    ? 'var(--color-error)'
                    : 'var(--color-primary-green-hover)'};
        }
        resize: none;
        overflow: hidden;
    }
    svg {
        position: absolute;
        right: 8px;
        top: 13px;
    }
`;

const StyledCustomDadataInputList = styled.div<{ $zidx?: number }>`
    position: absolute;
    z-index: ${({ $zidx }) => ($zidx ? $zidx + 2 : 1)};
    left: 0;
    width: 100%;
    padding: 10px;
    background-color: var(--color-bg-main);
    border-radius: var(--radius);
    box-shadow: var(--shadow-main);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-height: 200px;
    overflow: auto;
    gap: 10px;
    border: 1px solid var(--color-primary-green);
    border-radius: var(--radius);
    &::-webkit-scrollbar {
        width: 8px; /* ширина scrollbar */
    }
    &::-webkit-scrollbar-track {
        background: var(--color-bg-second); /* цвет дорожки */
        box-shadow: var(--shadow-main);
        border-radius: var(--radius);
    }
    &::-webkit-scrollbar-thumb {
        background-color: var(--color-primary-green); /* цвет плашки */
        border-radius: 20px; /* закругления плашки */
    }
`;
const StyledCustomDadataInputListItem = styled.div`
    cursor: pointer;
    transition: var(--transition);
    &:hover {
        color: var(--color-primary-green-hover);
    }
`;

export default memo(CustomDadataInput);
