import { FC } from 'react';
import styled from 'styled-components';
import { ReactComponent as SuccessIcon } from '../../../assets/icons/success.svg';
import CustomButton from '../CustomButton/CustomButton';
import { ReactComponent as PointAIcon } from '../../../assets/icons/mark1.svg';
import { ReactComponent as PointBIcon } from '../../../assets/icons/mark2.svg';
import { ReactComponent as ZzzIcon } from '../../../assets/icons/zzz.svg';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { setModalIsOpen } from '../../../store/slices/mainSlice';

const ThxMessage: FC = () => {
    const dispatch = useAppDispatch();
    const { date, time, tarif, pointADadataRaw, pointBDadataRaw } =
        useAppSelector((state) => state.main.inputsData);
    const isNight = useAppSelector((state) => state.main.isNight);

    const onClickClose = () => {
        dispatch(setModalIsOpen(false));
    };

    return (
        <StyledThxMessage $isNight={isNight}>
            <SuccessIcon />
            {isNight && <ZzzIcon />}
            <h4>Спасибо, ваш заказ принят!</h4>
            <StyledAddressesList $isNight={isNight}>
                <h5>{`Тариф «${tarif?.label}», ${date}, ${time}`}</h5>
                <StyledAddressItem $isNight={isNight}>
                    <PointAIcon />
                    {pointADadataRaw?.value}
                </StyledAddressItem>
                <StyledAddressItem $isNight={isNight}>
                    <PointBIcon />
                    {pointBDadataRaw?.value}
                </StyledAddressItem>
            </StyledAddressesList>
            {isNight ? (
                <p>
                    Так как вы оформили заказ в нерабочее время, наш оператор
                    свяжется с вами после 8:00 для уточнения деталей заказа и
                    расчета финальной стоимости поездки
                </p>
            ) : (
                <p>
                    Наш оператор перезвонит вам в течение часа для уточнения
                    деталей заказа и расчета финальной стоимости поездки
                </p>
            )}
            <CustomButton
                onClick={onClickClose}
                variant="primary"
                type="button"
                isNight={!isNight}
            >
                Понятно
            </CustomButton>
        </StyledThxMessage>
    );
};

const StyledThxMessage = styled.div<{ $isNight: boolean }>`
    max-width: 451px;
    padding: 64px;
    background: ${({ $isNight }) =>
        $isNight ? 'var(--color-night)' : 'var(--color-bg-main)'};
    border-radius: var(--radius);
    box-shadow: ${({ $isNight }) =>
        $isNight ? 'var(--shadow-second)' : 'var(--shadow-main)'};
    display: flex;
    flex-direction: column;
    gap: 48px;

    @media (max-width: 640px) {
        gap: 24px;
    }
    svg {
        align-self: center;
        margin-bottom: -16px;
        flex: none;
    }
    h4 {
        text-align: center;
        font-size: 22px;
        font-weight: 700;
        color: ${({ $isNight }) =>
            $isNight ? 'var(--color-text-white)' : 'var(--color-text)'};
        @media (max-width: 768px) {
            font-size: 18px;
        }
    }
    & > p {
        font-size: 16px;
        font-weight: 400;
        line-height: 150%;
        color: ${({ $isNight }) =>
            $isNight ? 'var(--color-text-white)' : 'var(--color-text)'};
        @media (max-width: 768px) {
            font-size: 15px;
        }
    }
    @media (max-width: 640px) {
        padding: 32px 16px;
    }
`;

const StyledAddressesList = styled.div<{ $isNight?: boolean }>`
    display: flex;
    flex-direction: column;
    gap: 24px;
    h5 {
        font-size: 16px;
        font-weight: 700;
        line-height: 150%;
        text-align: center;
        color: ${({ $isNight }) =>
            $isNight ? 'var(--color-text-white)' : 'var(--color-text-second)'};
        @media (max-width: 640px) {
            max-width: 170px;
            margin: 0 auto;
        }
    }
`;

const StyledAddressItem = styled.div<{ $isNight?: boolean }>`
    display: flex;
    align-items: center;
    gap: 10px;
    height: 39px;
    font-size: 14px;
    font-weight: 600;
    line-height: 150%;
    color: ${({ $isNight }) =>
        $isNight ? 'var(--color-text-white)' : 'var(--color-text-second)'};
    svg {
        align-self: baseline;
        height: 39px;
        width: 27px;
    }
`;

export default ThxMessage;
