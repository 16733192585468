import { FC, ReactNode } from 'react';
import { FieldError } from 'react-hook-form';
import styled from 'styled-components';

type ErrorMessageProps = {
    children: ReactNode;
    isError: boolean | FieldError;
};

const ErrorMessage: FC<ErrorMessageProps> = ({ children, isError }) => {
    return (
        <StyledErrorMessage $isError={isError}>{children}</StyledErrorMessage>
    );
};

const StyledErrorMessage = styled.div<{ $isError: boolean | FieldError }>`
    color: var(--color-error);
    font-size: 15px;
    height: 20px;
    text-align: center;
`;

export default ErrorMessage;
