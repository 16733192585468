/* eslint-disable react/display-name */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { debounce } from 'lodash';
import { FC, useEffect, useRef } from 'react';
import styled from 'styled-components';
import placemarkA from '../../../assets/icons/mark1.svg';
import placemarkB from '../../../assets/icons/mark2.svg';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { setMapData, setMapReload } from '../../../store/slices/mainSlice';
import { roundNumber } from '../../../utils/utils';

const CustomMap: FC = () => {
    const dispatch = useAppDispatch();
    const mapRef = useRef<any>(null);
    const { center, pointA, pointB } = useAppSelector(
        (state) => state.main.mapData,
    );
    const { mapReload } = useAppSelector((state) => state.main);
    const ymaps: any = (window as any).ymaps;

    function init() {
        if (mapRef.current && mapRef.current.children.length > 0) {
            const existingMap = mapRef.current.firstChild;
            existingMap.remove();
        }
        const myMap = new ymaps.Map('map', {
            center,
            zoom: 10,
            controls: ['zoomControl'],
        });

        const multiRoute = new ymaps.multiRouter.MultiRoute(
            {
                referencePoints: [pointA, pointB],
                params: {
                    routingMode: 'auto',
                    results: 1,
                    // reverseGeocoding: true,
                },
            },
            {
                boundsAutoApply: true,
                wayPointFinishIconLayout: 'default#image',
                wayPointFinishIconImageHref: placemarkB,
                wayPointFinishIcon_imagesize: [34, 49],
                wayPointFinishIconImageOffset: [-18, -40],
                wayPointStartIconLayout: 'default#image',
                wayPointStartIconImageHref: placemarkA,
                wayPointStartIcon_imagesize: [34, 49],
                wayPointStartIconImageOffset: [-18, -40],
                pinIconFillColor: '#333333',
                pinActiveIconFillColor: '#333333',
                // Внешний вид линии активного маршрута.
                routeActiveStrokeWidth: 6,
                routeActiveStrokeStyle: 'solid',
                routeActiveStrokeColor: '#00c855',
                // Внешний вид линий альтернативных маршрутов.
                routeStrokeStyle: 'dot',
                routeStrokeWidth: 3,
            },
        );
        // multiRoute.editor.start();
        myMap.geoObjects.add(multiRoute);

        multiRoute.model.events.add(
            'requestsuccess',
            debounce(() => {
                // Получение ссылки на активный маршрут.
                const activeRoute = multiRoute.getActiveRoute();
                if (activeRoute) {
                    dispatch(
                        setMapData({
                            distance: roundNumber(
                                activeRoute.properties.get('distance').value /
                                    1000,
                            ),

                            duration: roundNumber(
                                activeRoute.properties.get('durationInTraffic')
                                    .value / 60,
                            ),

                            // pointA: activeRoute.properties.get('boundedBy')[0],
                            // pointB: activeRoute.properties.get('boundedBy')[1],
                        }),
                    );
                }
            }, 1000),
        );
    }

    useEffect(() => {
        const reloadMap = () => {
            if (mapReload) {
                ymaps.ready(init);
            }
            dispatch(setMapReload(false));
        };

        reloadMap();
    }, [mapReload, dispatch, location]);

    return <StyledYmap ref={mapRef} id="map"></StyledYmap>;
};

const StyledYmap = styled.div`
    width: 100%;
    height: 666px;
    box-shadow: var(--shadow-main);
    border-radius: var(--border);
    @media (max-width: 992px) {
        height: 530px;
    }
`;

export default CustomMap;
