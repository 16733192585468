import { FC } from 'react';
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import CustomButton from '../CustomButton/CustomButton';
import { ReactComponent as SuccessIcon } from '../../../assets/icons/success.svg';
import { ReactComponent as ZzzIcon } from '../../../assets/icons/zzz.svg';
import { setModalIsOpen } from '../../../store/slices/mainSlice';

const ThxCallback: FC = () => {
    const dispatch = useAppDispatch();
    const isNight = useAppSelector((state) => state.main.isNight);

    const onClickClose = () => {
        dispatch(setModalIsOpen(false));
    };
    return (
        <StyledThxCallback $isNight={isNight}>
            <SuccessIcon />
            {isNight && <ZzzIcon />}
            {isNight ? (
                <p>
                    Так как вы оформили заказ в нерабочее время, наш оператор
                    свяжется с вами после 8:00 для уточнения деталей заказа и
                    расчета финальной стоимости поездки
                </p>
            ) : (
                <p>
                    Наш оператор перезвонит вам в течение часа для уточнения
                    деталей заказа и расчета финальной стоимости поездки
                </p>
            )}
            <CustomButton
                onClick={onClickClose}
                variant="primary"
                type="button"
                isNight={!isNight}
            >
                Понятно
            </CustomButton>
        </StyledThxCallback>
    );
};

const StyledThxCallback = styled.div<{ $isNight?: boolean }>`
    max-width: 451px;
    padding: 64px;
    background: ${({ $isNight }) =>
        $isNight ? 'var(--color-night)' : 'var(--color-bg-main)'};
    border-radius: var(--radius);
    box-shadow: ${({ $isNight }) =>
        $isNight ? 'var(--shadow-second)' : 'var(--shadow-main)'};
    display: flex;
    flex-direction: column;
    gap: 48px;

    @media (max-width: 640px) {
        gap: 24px;
        padding: 32px 16px;
    }
    & > p {
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        line-height: 150%;
        color: ${({ $isNight }) =>
            $isNight ? 'var(--color-text-white)' : 'var(--color-text)'};
        @media (max-width: 768px) {
            font-size: 15px;
        }
    }
    svg {
        align-self: center;
        margin-bottom: -16px;
        flex: none;
    }
`;

export default ThxCallback;
