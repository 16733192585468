import { FC, memo } from 'react';
import styled from 'styled-components';
import Hero from '../../Hero/Hero';
import Advantages from '../../Advantages/Advantages';
import About from '../../About/About';
import Gratitude from '../../Gratitude/Gratitude';
import Tarifs from '../../Tarifs/Tarifs';

const MainPage: FC = () => {
    return (
        <StyledMainPage>
            <Hero />
            <Tarifs />
            <Advantages />
            <About />
            <Gratitude />
        </StyledMainPage>
    );
};

const StyledMainPage = memo(styled.div``);

export default memo(MainPage);
