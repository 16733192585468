import { FC, memo, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import './index.css';
import Modal from './components/Modal/Modal';
import { useAppDispatch, useAppSelector } from './store/hooks';
import { setMapReload } from './store/slices/mainSlice';

const App: FC = () => {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const modalIsOpen = useAppSelector((state) => state.main.modalIsOpen);
    // const modalType = useAppSelector((state) => state.main.modalType);

    useEffect(() => {
        if (modalIsOpen) {
            document.body.style.overflow = 'hidden';
            document.body.style.paddingRight = '15px';
        } else {
            document.body.style.overflow = 'auto';
            document.body.style.paddingRight = '0';
        }
    }, [modalIsOpen]);

    useEffect(() => {
        if (location.pathname !== '/order') {
            dispatch(setMapReload(true));
        }
        if (location.hash) {
            const elem = document.getElementById(location.hash.slice(1));
            if (elem) {
                const topPosition =
                    elem.getBoundingClientRect().top + window.pageYOffset - 70;
                window.scrollTo({
                    top: topPosition,
                    left: 0,
                    behavior: 'smooth',
                });
            }
        } else {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        }
        window.history.replaceState(null, '', window.location.pathname);
    }, [location, dispatch]);

    return (
        <StyledApp>
            <Header />
            <StyledOutlet />
            <Footer />
            {modalIsOpen && <Modal />}
        </StyledApp>
    );
};

const StyledOutlet = styled(Outlet)`
    flex: 1 0 auto;
`;

const StyledApp = memo(styled.div`
    min-width: 320px;
    display: flex;
    flex-direction: column;
    min-height: 100%;
`);

export default memo(App);
