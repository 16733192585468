import { FC } from 'react';
import styled from 'styled-components';
import { Container } from '../../styles/container.styled';
import { StyledSection } from '../../styles/section.styled';
import { ReactComponent as AdvantageIconFirst } from '../../assets/images/advantages/adv1.svg';
import { ReactComponent as AdvantageIconSecond } from '../../assets/images/advantages/adv2.svg';
import { ReactComponent as AdvantageIconThird } from '../../assets/images/advantages/adv3.svg';

const Advantages: FC = () => {
    return (
        <StyledAdvantages>
            <StyledSection>
                <Container>
                    <StyledAdvantagesList>
                        <StyledAdvantagesItem>
                            <AdvantageIconFirst />
                            <p>
                                Профессиональные водители и санитары с большим
                                опытом
                            </p>
                        </StyledAdvantagesItem>
                        <StyledAdvantagesItem>
                            <AdvantageIconSecond />
                            <p>Адаптированный транспорт</p>
                        </StyledAdvantagesItem>
                        <StyledAdvantagesItem>
                            <AdvantageIconThird />
                            <p>Работаем с юр. лицами на тех же условиях</p>
                        </StyledAdvantagesItem>
                    </StyledAdvantagesList>
                </Container>
            </StyledSection>
        </StyledAdvantages>
    );
};

const StyledAdvantages = styled.div``;
const StyledAdvantagesList = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 24px;
    @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
    }
`;

const StyledAdvantagesItem = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 280px;
    text-align: center;
    gap: 24px;
    @media (max-width: 768px) {
        gap: 8px;
    }
    svg {
        filter: drop-shadow(0px 10px 20px rgba(0, 100, 205, 0.15));
        @media (max-width: 768px) {
            width: 120px;
            height: 120px;
        }
    }

    p {
        font-size: 20px;
        font-weight: 500;
        line-height: 130%;
        @media (max-width: 768px) {
            font-size: 18px;
        }
    }
    &:first-child,
    &:last-child {
        max-width: 350px;
    }
`;

export default Advantages;
