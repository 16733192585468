import FsLightbox from 'fslightbox-react';
import { FC, useState } from 'react';
import styled from 'styled-components';
import 'swiper/css';
import { Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { grats } from './Gratitude.constants';
import { nanoid } from '@reduxjs/toolkit';

const GratitudeSlider: FC = () => {
    const [toggler, setToggler] = useState<boolean>(false);
    const [selectedImageIndex, setSelectedImageIndex] = useState<number>(0);
    return (
        <>
            <StyledGratitudeSwiper>
                <Swiper
                    modules={[Pagination, Navigation]}
                    spaceBetween={30}
                    slidesPerView={1}
                    navigation
                    loop={true}
                    pagination={{ clickable: true }}
                    breakpoints={{
                        640: {
                            slidesPerView: 2,
                            spaceBetween: 32,
                        },
                        768: {
                            slidesPerView: 3,
                            spaceBetween: 32,
                        },
                        992: {
                            slidesPerView: 4,
                            spaceBetween: 32,
                        },
                    }}
                >
                    {grats.map((im) => (
                        <SwiperSlide key={nanoid()}>
                            <StyledGratitudeSwiperSlide
                                onClick={() => {
                                    setSelectedImageIndex(grats.indexOf(im));
                                    setToggler(!toggler);
                                }}
                            >
                                <img
                                    src={im}
                                    width="100%"
                                    height="auto"
                                    alt="Image"
                                />
                            </StyledGratitudeSwiperSlide>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </StyledGratitudeSwiper>
            <FsLightbox
                toggler={toggler}
                sources={grats}
                sourceIndex={selectedImageIndex}
            />
        </>
    );
};

const StyledGratitudeSwiper = styled.div`
    && .swiper {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(255px, 1fr));
        padding: 48px 15px 50px 15px;
        @media (max-width: 768px) {
            margin: 0 -15px 0 -15px;
            padding-bottom: 38px;
            padding-top: 24px;
        }
    }

    && .swiper-pagination-bullet-active {
        background: var(--color-primary-green);
    }

    && .swiper-button-next:after,
    .swiper-button-prev:after {
        color: var(--color-primary-green);
        font-size: 20px;
    }
    && .swiper-button-prev {
        left: -8px;
        @media (max-width: 768px) {
            display: none;
        }
    }
    && .swiper-button-next {
        right: -8px;
        @media (max-width: 768px) {
            display: none;
        }
    }
`;
const StyledGratitudeSwiperSlide = styled.div`
    box-shadow: var(--shadow-main);
    border-radius: var(--radius);
    cursor: pointer;
`;

export default GratitudeSlider;
