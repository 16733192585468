import { FC } from 'react';
import styled from 'styled-components';
import { ReactComponent as WarningIcon } from '../../../assets/icons/warn.svg';

const ThxlimitKm: FC = () => {
    return (
        <StyledThxlimitKm>
            <WarningIcon />
            <p>
                Так как указанная длина маршрута превышает 100 км, стоимость
                поездки будет рассчитана оператором индивидуально. Пожалуйста,
                свяжитесь с нами по номеру{' '}
                <StyledThxlimitKmPhone href="tel:8 800 551-10-31">
                    8 800 551-10-31
                </StyledThxlimitKmPhone>
            </p>
        </StyledThxlimitKm>
    );
};

const StyledThxlimitKmPhone = styled.a`
    font-weight: 700;
    cursor: pointer;
`;

const StyledThxlimitKm = styled.div`
    max-width: 451px;
    padding: 64px;
    background: var(--color-bg-main);
    border-radius: var(--radius);
    box-shadow: var(--shadow-main);
    display: flex;
    flex-direction: column;
    gap: 48px;

    @media (max-width: 640px) {
        gap: 24px;
        padding: 32px 16px;
    }
    & > p {
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        line-height: 150%;
        color: var(--color-text);
        @media (max-width: 768px) {
            font-size: 15px;
        }
    }
    svg {
        align-self: center;
        margin-bottom: -16px;
        flex: none;
    }
`;

export default ThxlimitKm;
