// const BASE_URL: string = 'https://api.mc21.ru/lk/v1/geo';
// const BASE_URL2: string = 'https://portal.mc21.ru/srv/taxi/lead';
// const BASE_URL3: string = 'https://portal.mc21.ru/srv/taxi/calc';
const BASEURL: string = 'https://osobennoe-taxi.ru/ajax.php';
// const BASEURL: string = 'ajax.php';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Obj = Record<string, any>;

export const fetchPost = async (data: Obj, action: string) => {
    const headers = new Headers();

    const options = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({ ...data, action }),
    };

    const res = await fetch(`${BASEURL}`, options);

    if (!res.ok) throw new Error('Failed to fetch');

    return res.json();
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any

// export const fetchPostLeed = async (data: Obj) => {
//     const headers = new Headers();
//     headers.append(
//         'Authorization',
//         'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJleHAiOjQwNzA4OTgwMDAsIlUiOiJUQVhJIiwiQSI6bnVsbH0.ZP5wYMSUIX-uCxM-oZ1cmcMs7Seb0vZitA6JnbM5aENcU-L2-pOMSX3yVemJJJINk61GwRaa62bR4EDtLhYnZpzgHvXOMjDx-wpAXi5ARHbdvSz8wknuj43pb3KQVL9BNBjUwWOyxTRjTC1X1drcBmSv7dUb9FOPQ7VlVZac1m6mvzTx4lZeLyYi3sn_Er6DtlhsIQgFlstG0iRw7zmZjYYLhEL25M-u3-A3UaOm5KQspENUF1PHQqEMKct-vXsg04sLfEe-52W2Dd7Yp1OiAqV0DwGJJe8Gu4WVTeyjWDrRaDu6ABAnvNtDd6PXdcExyJE6Rhauvf4Fafgm8RVz9w',
//     );
//     const options = {
//         method: 'POST',
//         headers: headers,
//         body: JSON.stringify(data),
//     };

//     const res = await fetch(`${BASE_URL2}`, options);

//     if (!res.ok) throw new Error('Failed to fetch');

//     return res.json();
// };

// export const fetchCalc = async (data: Obj) => {
//     const headers = new Headers();
//     headers.append(
//         'Authorization',
//         'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJleHAiOjQwNzA4OTgwMDAsIlUiOiJUQVhJIiwiQSI6bnVsbH0.ZP5wYMSUIX-uCxM-oZ1cmcMs7Seb0vZitA6JnbM5aENcU-L2-pOMSX3yVemJJJINk61GwRaa62bR4EDtLhYnZpzgHvXOMjDx-wpAXi5ARHbdvSz8wknuj43pb3KQVL9BNBjUwWOyxTRjTC1X1drcBmSv7dUb9FOPQ7VlVZac1m6mvzTx4lZeLyYi3sn_Er6DtlhsIQgFlstG0iRw7zmZjYYLhEL25M-u3-A3UaOm5KQspENUF1PHQqEMKct-vXsg04sLfEe-52W2Dd7Yp1OiAqV0DwGJJe8Gu4WVTeyjWDrRaDu6ABAnvNtDd6PXdcExyJE6Rhauvf4Fafgm8RVz9w',
//     );
//     const options = {
//         method: 'POST',
//         headers: headers,
//         body: JSON.stringify(data),
//     };

//     const res = await fetch(`${BASE_URL3}`, options);

//     if (!res.ok) throw new Error('Failed to fetch');

//     return res.json();
// };
