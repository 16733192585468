import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Container } from '../../styles/container.styled';
import Logo from '../UI/Logo/Logo';
import PhoneLink from '../UI/PhoneLink/PhoneLink';
import BurgerMenu from './BurgerMenu/BurgerMenu';
import Navbar from './Navbar/Navbar';
import { ReactComponent as VkIcon } from '../../assets/icons/vk.svg';
import { ReactComponent as LogoColored } from '../../assets/icons/logo.svg';

type StyledHeaderProps = {
    $isOpen: boolean;
};

const Header: FC = () => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const onClickBurgerMenu = useCallback(() => setIsOpen((prev) => !prev), []);

    const burgerMenuProps = useMemo(() => {
        return {
            isOpen,
            onClickBurgerMenu,
        };
    }, [isOpen, onClickBurgerMenu]);

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }, [isOpen]);

    return (
        <StyledHeader $isOpen={isOpen}>
            <Container>
                <StyledHeaderWrapper $isOpen={isOpen}>
                    <Logo type="colored" isOpen={isOpen}>
                        <LogoColored />
                    </Logo>
                    <Navbar isOpen={isOpen} burgerMenuClose={setIsOpen} />
                    <PhoneLink
                        phone={'8 800 533-79-17'}
                        icon
                        onlyIconMobile={true}
                        isOpen={isOpen}
                    />
                    {isOpen && (
                        <StyledVK
                            href="https://vk.com/medtaxi"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <VkIcon />
                        </StyledVK>
                    )}
                    <BurgerMenu {...burgerMenuProps} />
                </StyledHeaderWrapper>
            </Container>
        </StyledHeader>
    );
};

const StyledVK = styled.a`
    cursor: pointer;
    width: 40px;
    height: 40px;
    order: 4;
`;

const StyledHeaderWrapper = styled.div<StyledHeaderProps>`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: ${({ $isOpen }) => ($isOpen ? 'column' : 'row')};
    @media (max-width: 992px) {
        gap: 32px;
    }
`;
const StyledHeader = styled.header<StyledHeaderProps>`
    height: ${({ $isOpen }) => ($isOpen ? '100vh' : 'auto')};
    overflow: ${({ $isOpen }) => ($isOpen ? 'hidden' : 'auto')};
    padding: 24px 0;
    background: var(--color-bg-main);
    position: sticky;
    z-index: 20;
    box-shadow: var(--shadow-main);
    top: 0;
    right: 0;
    left: 0;
    min-width: 320px;
    @media (max-width: 992px) {
        padding: ${({ $isOpen }) => ($isOpen ? '48px 0' : '16px 0')};
    }
`;

export default memo(Header);
