import { FC, memo } from 'react';
import styled from 'styled-components';
import PageTitle from '../UI/PageTitle/PageTitle';
import { StyledSection } from '../../styles/section.styled';
import { Container } from '../../styles/container.styled';
import { ReactComponent as HeroBannerFirst } from '../../assets/images/banners/ban1.svg';
import FormCallback from '../UI/FormCallback/FormCallback';

const Hero: FC = () => {
    return (
        <StyledHero id="form">
            <StyledSection>
                <Container>
                    <PageTitle
                        title="Особенное такси"
                        subTitle="Комфортная и безопасная перевозка пассажиров-колясочников по Санкт-Петербургу и Ленинградской области"
                    />
                    <StyledHeroWrapper>
                        <FormCallback />
                        <StyledHeroBannerFirst />
                    </StyledHeroWrapper>
                </Container>
            </StyledSection>
        </StyledHero>
    );
};

const StyledHeroBannerFirst = memo(styled(HeroBannerFirst)`
    display: block;
    width: 100%;
    height: auto;
    max-width: 524px;
`);

const StyledHeroWrapper = memo(styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 32px;
    @media (max-width: 992px) {
        flex-direction: column-reverse;
    }
`);
const StyledHero = memo(styled.div`
    padding-top: 80px;
    background: var(--color-bg-second);
    box-shadow: var(--shadow-main);
    @media (max-width: 992px) {
        padding-top: 32px;
    }
`);

export default memo(Hero);
