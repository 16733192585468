import { FC } from 'react';
import styled from 'styled-components';
import Logo from '../../UI/Logo/Logo';
import PhoneLink from '../../UI/PhoneLink/PhoneLink';
import { ReactComponent as LogoWhite } from '../../../assets/icons/logo-white.svg';

const FooterLeft: FC = () => {
    return (
        <StyledFooterLeft>
            <Logo type="white">
                <LogoWhite />
            </Logo>
            <StyledFooterLeftMobileWrapper>
                <StyledFooterLeftComment>
                    Группа компаний Медицинский центр «XXI век» © 2023
                </StyledFooterLeftComment>
                <PhoneLink
                    phone={'8 800 533-79-17'}
                    icon={false}
                    onlyIconMobile={false}
                    color={'var(--color-text-white)'}
                />
            </StyledFooterLeftMobileWrapper>
        </StyledFooterLeft>
    );
};

const StyledFooterLeftMobileWrapper = styled.div`
    div {
        @media (max-width: 992px) {
            display: none;
        }
    }
    a {
        display: none;
        @media (max-width: 992px) {
            display: flex;
        }
    }
`;

const StyledFooterLeft = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    flex: none;
    @media (max-width: 992px) {
        flex-direction: column;
        align-items: center;
    }
`;

export const StyledFooterLeftComment = styled.div`
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
    color: var(--color-text-white);
    max-width: 166px;
    @media (max-width: 992px) {
        text-align: center;
    }
`;

export default FooterLeft;
