import styled from 'styled-components';

export const StyledTitle = styled.h3`
    font-size: 36px;
    font-weight: 700;
    line-height: 120%;
    text-align: center;
    @media (max-width: 992px) {
        font-size: 22px;
    }
`;
