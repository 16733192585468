import { ChangeEvent, Dispatch, FC, SetStateAction } from 'react';
import styled from 'styled-components';
import { ReactComponent as ArrowDownIcon } from '../../../assets/icons/arrow-down.svg';
import { ReactComponent as ArrowUpIcon } from '../../../assets/icons/arrow-up.svg';
import { ReactComponent as CrossIcon } from '../../../assets/icons/crossdate.svg';

interface ICustomDateTimePikerTimeinputsProps {
    hours: string;
    minutes: string;
    setHours: Dispatch<SetStateAction<string>>;
    setMinutes: Dispatch<SetStateAction<string>>;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
}

const CustomDateTimePikerTimeinputs: FC<
    ICustomDateTimePikerTimeinputsProps
> = ({ hours, minutes, setHours, setMinutes, setIsOpen }) => {
    const onClickIncrementHours = () => {
        setHours((prevHours: string) => {
            const hours = parseInt(prevHours);
            if (hours >= 23) {
                return '00';
            } else {
                return (hours + 1).toString().padStart(2, '0');
            }
        });
    };
    const onClickDecrementHours = () => {
        setHours((prevHours: string) => {
            const hours = parseInt(prevHours);
            if (hours <= 0) {
                return '23';
            } else {
                return (hours - 1).toString().padStart(2, '0');
            }
        });
    };
    const onClickIncrementMinutes = () => {
        setMinutes((prevMinutes: string) => {
            const minutes = parseInt(prevMinutes);
            if (minutes >= 59) {
                return '00';
            } else {
                return (minutes + 1).toString().padStart(2, '0');
            }
        });
    };
    const onClickDecrementMinutes = () => {
        setMinutes((prevMinutes: string) => {
            const minutes = parseInt(prevMinutes);
            if (minutes <= 0) {
                return '59';
            } else {
                return (minutes - 1).toString().padStart(2, '0');
            }
        });
    };
    const formatedInputValue = (
        e: ChangeEvent<HTMLInputElement>,
        action: (prevValue: string) => void,
        name: 'hours' | 'minutes',
    ) => {
        const inputValue = e.target.value;
        const regex = /^[0-9]*$/;
        if (regex.test(inputValue)) {
            if (name === 'hours') {
                const hours = parseInt(inputValue);
                if (hours > 23) {
                    action('23');
                } else {
                    action(inputValue);
                }
            }
            if (name === 'minutes') {
                const minutes = parseInt(inputValue);
                if (minutes > 59) {
                    action('59');
                } else {
                    action(inputValue);
                }
            }
        }
    };

    const onBlurInputValue = (
        e: ChangeEvent<HTMLInputElement>,
        action: (prevValue: string) => void,
    ) => {
        const inputValue = e.target.value;
        if (inputValue === '') {
            action('00');
        }
    };

    return (
        <StyledCustomDateTimePikerTimeinputs>
            <StyledCustomDateTimePickerClose onClick={() => setIsOpen(false)} />
            <StyledTimeInput>
                <StyledArrowUpIcon onClick={onClickIncrementHours} />
                <input
                    className="noselect"
                    type="text"
                    id="hours"
                    maxLength={2}
                    value={hours.toString().padStart(2, '0')}
                    onBlur={(e) => onBlurInputValue(e, setHours)}
                    onChange={(e) => formatedInputValue(e, setHours, 'hours')}
                />
                <StyledArrowDownIcon onClick={onClickDecrementHours} />
            </StyledTimeInput>
            <StyledTimeInput>
                <StyledArrowUpIcon onClick={onClickIncrementMinutes} />
                <input
                    className="noselect"
                    type="text"
                    id="minutes"
                    maxLength={2}
                    value={minutes.toString().padStart(2, '0')}
                    onBlur={(e) => onBlurInputValue(e, setMinutes)}
                    onChange={(e) =>
                        formatedInputValue(e, setMinutes, 'minutes')
                    }
                />
                <StyledArrowDownIcon onClick={onClickDecrementMinutes} />
            </StyledTimeInput>
        </StyledCustomDateTimePikerTimeinputs>
    );
};

const StyledCustomDateTimePickerClose = styled(CrossIcon)`
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
    z-index: 1;
`;

const StyledArrowUpIcon = styled(ArrowUpIcon)`
    cursor: pointer;
`;
const StyledArrowDownIcon = styled(ArrowDownIcon)`
    cursor: pointer;
`;

const StyledTimeInput = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    && input {
        flex: none;
        border-top: 1px solid var(--color-primary-green);
        border-bottom: 1px solid var(--color-primary-green);
        padding: 5px;
        width: 40px !important;
        height: 40px;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        line-height: 130%;
        outline: none;
        user-select: none !important;
        &:focus {
            outline: none;
            box-shadow: none;
        }
        &::selection {
            background: transparent;
        }
        ::-moz-selection {
            background: transparent;
        }
    }
`;

const StyledCustomDateTimePikerTimeinputs = styled.div`
    display: flex;
    gap: 20px;
`;

export default CustomDateTimePikerTimeinputs;
