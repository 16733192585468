import imageSource1 from '../../../assets/images/slides/сайт ММГН фото 2 1920-min.jpg';
import imageSource2 from '../../../assets/images/slides/сайт ММГН фото 12 1920-min.jpg';
import imageSource3 from '../../../assets/images/slides/сайт ММГН фото 9 1920-min.jpg';
import imageSource4 from '../../../assets/images/slides/сайт ММГН фото 7 1920-min.jpg';
import imageSource5 from '../../../assets/images/slides/сайт ММГН фото 13 1920-min.jpg';
import imageSource6 from '../../../assets/images/slides/сайт ММГН фото 6 1920-min.jpg';
import imageSource7 from '../../../assets/images/slides/сайт ММГН фото 15 1920-min.jpg';
import imageSource8 from '../../../assets/images/slides/сайт ММГН фото 10 1920-min.jpg';

export const aboutSlides = [
    imageSource1,
    imageSource2,
    imageSource3,
    imageSource4,
    imageSource5,
    imageSource6,
    imageSource7,
    imageSource8,
];
