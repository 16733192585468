import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    CoordinatesType,
    initialStateType,
    InputsDataType,
    MapDataType,
    ModalType,
} from './mainSlice.types';

const initialState: initialStateType = {
    isRent: JSON.parse(localStorage.getItem('isRent') ?? 'false'),
    mapData: JSON.parse(
        localStorage.getItem('mapData') ??
            JSON.stringify({
                center: [59.9386, 30.3141],
                pointA: [],
                pointB: [],
                distance: 0,
                duration: 0,
            }),
    ),
    mapReload: true,
    modalIsOpen: JSON.parse(localStorage.getItem('modalIsOpen') ?? 'false'),
    inputsData: JSON.parse(
        localStorage.getItem('inputsData') ??
            JSON.stringify({
                name: '',
                phone: '',
                pointADadataRaw: {
                    data: {},
                    value: '',
                    unrestricted_value: '',
                },
                pointBDadataRaw: {
                    data: {},
                    value: '',
                    unrestricted_value: '',
                },
                date: '',
                time: '',
                tarif: { label: '', value: '' },
            }),
    ),
    price: (localStorage.getItem('price') as string) ?? '',
    modalType:
        JSON.parse(localStorage.getItem('modalType') as ModalType) ||
        'callback',
    isNight: JSON.parse(localStorage.getItem('isNight') ?? 'false'),
};

const mainSlice = createSlice({
    name: 'main',
    initialState,
    reducers: {
        setIsNight(state, action: PayloadAction<boolean>) {
            state.isNight = action.payload;
            localStorage.setItem('isNight', JSON.stringify(state.isNight));
        },
        setModalType(state, action: PayloadAction<ModalType>) {
            state.modalType = action.payload;
            localStorage.setItem('modalType', JSON.stringify(state.modalType));
        },
        setPrice(state, action: PayloadAction<string>) {
            state.price = action.payload;
            localStorage.setItem('price', JSON.stringify(state.price));
        },
        setSetIsRent(state, action: PayloadAction<boolean>) {
            state.isRent = action.payload;
            localStorage.setItem('isRent', JSON.stringify(state.isRent));
        },
        setMapData(state, action: PayloadAction<MapDataType>) {
            state.mapData = {
                ...state.mapData,
                ...action.payload,
            };
            localStorage.setItem('mapData', JSON.stringify(state.mapData));
        },
        setInputsData(state, action: PayloadAction<InputsDataType>) {
            state.inputsData = {
                ...state.inputsData,
                ...action.payload,
            };
            localStorage.setItem(
                'inputsData',
                JSON.stringify(state.inputsData),
            );
        },
        setMapDataFromDadataInputs(
            state,
            action: PayloadAction<{
                pointA?: CoordinatesType;
                pointB?: CoordinatesType;
            }>,
        ) {
            const { pointA, pointB } = action.payload;
            if (pointA) {
                state.mapData.pointA = pointA;
            }
            if (pointB) {
                state.mapData.pointB = pointB;
            }
            state.mapReload = true;
            localStorage.setItem('mapData', JSON.stringify(state.mapData));
        },
        setMapReload(state, action: PayloadAction<boolean>) {
            state.mapReload = action.payload;
        },
        setModalIsOpen(state, action: PayloadAction<boolean>) {
            state.modalIsOpen = action.payload;
            localStorage.setItem(
                'modalIsOpen',
                JSON.stringify(state.modalIsOpen),
            );
        },
    },
});

export const {
    setSetIsRent,
    setMapData,
    setMapDataFromDadataInputs,
    setMapReload,
    setModalIsOpen,
    setInputsData,
    setPrice,
    setModalType,
    setIsNight,
} = mainSlice.actions;
export default mainSlice.reducer;
